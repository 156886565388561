<template>
  <div>
    <div
      class="block tool_group_pop tool_pop"
      :style="{
        top: this.mouse_y + 25 + 'px',
        left: this.mouse_x - 10 + 'px',
      }"
    >
      <div
        v-if="tool_popup"
        class="px-1 py-1 border-green-700 text-green-700 tooltip_hydraC"
      >
        <span v-if="!isNoData" class="tooltiptext">
          <div class="grid-container">
            <div class="item1 _item_">
              <div class="mx-auto">
                <div
                  v-if="isPipeString"
                  class="left_width font_xs_tooltip py-2"
                >
                  <p class="left_width font_xs_tooltip">
                    Hole Depth:
                    {{
                      this.data.dmea ? parseFloat(this.data.dmea).toFixed(2) : 0
                    }}
                    {{ this.isWellMetrics ? 'm' : 'ft' }}
                  </p>
                  <p class="left_width font_xs_tooltip">
                    Bit Depth: {{ parseFloat(this.data.dbtm).toFixed(2) }} {{ this.isWellMetrics ? 'm' : 'ft' }}
                  </p>
                  <p class="left_width font_xs_tooltip">
                    Pill Depth:
                    {{
                      this.pill_depth_annular && this.pill_depth_annular >= 0
                        ? this.pill_depth_annular.toFixed(2)
                        : this.pill_depth && this.pill_depth >= 0
                        ? this.pill_depth.toFixed(2)
                        : 0
                    }}
                    {{ this.isWellMetrics ? 'm' : 'ft' }}
                  </p>
                  <p class="left_width font_xs_tooltip">
                    Current Strokes:
                    {{ this.current_strokes }}
                    stks
                  </p>
                  <template
                    v-if="
                      parseInt(dry_pipe_length) &&
                      parseInt(slug_weight) &&
                      (hydrostatic_pressure ||
                        gradient_btn_slug_mud_wt ||
                        slug_length ||
                        slugVolume ||
                        this.data['dpcapacity'])
                    "
                  >
                    <br />

                    <p class="tooltip__heading">
                      Barrels of slug required for a desired length of dry pipe
                    </p>
                    <p
                      v-if="hydrostatic_pressure"
                      class="left_width font_xs_tooltip"
                    >
                      Hydrostatic Pressure required to give desired drop in DP:
                      {{ hydrostatic_pressure.toFixed(2) }} psi
                    </p>
                    <p
                      v-if="gradient_btn_slug_mud_wt"
                      class="left_width font_xs_tooltip"
                    >
                      Difference in pressure gradient between SW & MW:
                      {{ gradient_btn_slug_mud_wt.toFixed(2) }} psi/ft
                    </p>
                    <p v-if="slug_length" class="left_width font_xs_tooltip">
                      Length of Slug in DP: {{ slug_length.toFixed(2) }} ft
                    </p>
                    <p
                      v-if="calc_slugVolume"
                      class="left_width font_xs_tooltip"
                    >
                      Vol. of Slug: {{ calc_slugVolume.toFixed(2) }} bbls
                    </p>
                    <p
                      v-if="this.data['dpcapacity']"
                      class="left_width font_xs_tooltip"
                    >
                      DP Capacity:
                      {{ parseFloat(this.data["dpcapacity"]).toFixed(3) }}
                      bbl/ft
                    </p>
                  </template>
                  <template
                    v-if="
                      parseInt(slug_dry_pipe_length) &&
                      parseInt(slug_volume) &&
                      (calc_slug_weight ||
                        calc_slug_length ||
                        calc_hydrostatic_pressure_for_drop)
                    "
                  >
                    <br />
                    <p class="tooltip__heading">
                      Weight of slug required for a desired length of dry pipe
                    </p>
                    <p
                      v-if="calc_slug_length"
                      class="left_width font_xs_tooltip"
                    >
                      Length of slug in DP:
                      {{ parseFloat(calc_slug_length).toFixed(2) }} ft
                    </p>
                    <p
                      v-if="calc_hydrostatic_pressure_for_drop"
                      class="left_width font_xs_tooltip"
                    >
                      Hydrostatic pressure required to give desired pressure
                      drop in DP:
                      {{
                        parseFloat(calc_hydrostatic_pressure_for_drop).toFixed(
                          2
                        )
                      }}
                      psi
                    </p>
                    <p
                      v-if="calc_slug_weight"
                      class="left_width font_xs_tooltip"
                    >
                      Weight of Slug:
                      {{ parseFloat(calc_slug_weight).toFixed(2) }} ppg
                    </p>
                  </template>

                  <br v-if="heightGained || pressureGained || volumeGained" />
                  <p
                    v-if="heightGained || pressureGained || volumeGained"
                    class="tooltip__heading"
                  >
                    Volume, height, pressure gained because of slug
                  </p>
                  <p v-if="heightGained" class="left_width font_xs_tooltip">
                    Ht of Slug in Ann: {{ heightGained.toFixed(2) }} ft
                  </p>
                  <p v-if="pressureGained" class="left_width font_xs_tooltip">
                    Hydrostatic Pressure Gain in Ann :
                    {{ pressureGained.toFixed(2) }} psi
                  </p>
                  <p v-if="volumeGained" class="left_width font_xs_tooltip">
                    Vol Gain in Pit : {{ volumeGained.toFixed(2) }} bbls
                  </p>
                  <br
                    v-if="
                      total_volume_spotting_fluid || spot_pill_strokes_required
                    "
                  />
                  <p
                    class="tooltip__heading"
                    v-if="
                      total_volume_spotting_fluid ||
                      stroke_required_pump_pill ||
                      spot_pill_strokes_required ||
                      volume_required_pill_chase ||
                      stroke_required_chase_pill
                    "
                  >
                    Spotting pill calculation
                  </p>

                  <p
                    v-if="total_volume_spotting_fluid"
                    class="left_width font_xs_tooltip"
                  >
                    Total Barrels of spotting pill required:
                    {{ this.total_volume_spotting_fluid.toFixed(2) }} bbls
                  </p>
                  <p
                    v-if="stroke_required_pump_pill"
                    class="left_width font_xs_tooltip"
                  >
                    Strokes required to pump pill:
                    {{ this.stroke_required_pump_pill.toFixed(2) }} stks
                  </p>
                  <p
                    v-if="volume_required_pill_chase"
                    class="left_width font_xs_tooltip"
                  >
                    Volume(bbls) required to chase pill:
                    {{ this.volume_required_pill_chase.toFixed(2) }} bbls
                  </p>
                  <p
                    v-if="stroke_required_chase_pill"
                    class="left_width font_xs_tooltip"
                  >
                    Strokes required to chase pill:
                    {{ this.stroke_required_chase_pill.toFixed(2) }} stks
                  </p>
                  <p
                    v-if="spot_pill_strokes_required"
                    class="left_width font_xs_tooltip"
                  >
                    Strokes Required to spot pill:
                    {{ this.spot_pill_strokes_required.toFixed(2) }} stks
                  </p>
                </div>
                <div v-if="!isPipeString && this.geometry" class="left_width">
                  Casing Details
                  <p class="left_width font_xs_tooltip">
                    Section Type: {{ this.geometry.section_type }}
                  </p>
                  <p class="left_width font_xs_tooltip">
                    Casing OD: {{ this.geometry.odia }} inch
                  </p>
                  <p class="left_width font_xs_tooltip">
                    Casing ID: {{ this.geometry.idia }} inch
                  </p>
                  <p class="left_width font_xs_tooltip">
                    Casing Depth: {{ this.geometry.md }} ft
                  </p>
                  <p class="left_width font_xs_tooltip">
                    TVD: {{ this.geometry.tvd }} ft
                  </p>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
    <div
      :id="widgetId"
      @drop="onDropOnWidgets($event, 1)"
      @contextmenu="this.setContextIdWidget"
      :style="{
        height: this.widgetHeight + 'px',
        width: this.widgetWidth + 'px',
        overflowy: 'hidden',
        paddingTop: '5px',
        borderWidth: '0px',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '10px',
        // background: darkDark !== 'white' ? this.cardBackground : '#FFFFF7',
         background : 'var(--navBar2Bg)'
      }"
      class="bg_purple shadow-lg cust_shadow"
      style="position: relative"
      @mouseover="isHovering = true"
      @mouseout="isHovering = false"
    >
      <div
        :id="widgetId"
        @drop="onDropOnWidgets($event, 1)"
        @contextmenu="this.setContextIdWidget"
        :style="{
          height: this.widgetHeight - 30 + 'px',
          width: this.widgetWidth - 100 + 'px',
          overflowy: 'hidden',
          paddingTop: '5px',
          borderWidth: '0px',

          // background: darkDark !== 'white' ? this.cardBackground : '#FFFFF7',
          background : 'var(--navBar2Bg)'
        }"
        class="mx-auto bg_purple shadow-lg cust_shadow"
        style="position: relative"
        @mouseover="isHovering = true"
        @mouseout="isHovering = false"
      >
        <div class="flex items-center justify-center">
          <canvas
            ref="left_casing"
            width="50"
            :height="casingHeight"
            :style="{ order: 1 }"
            @mousemove="casingMouseMoveHandler('left', $event)"
          ></canvas>
          <div :id="'hydraulics-calculator' + widgetId" :style="{ order: 2 }">
            <span
              v-if="isNoData"
              class="font_xs_tooltip flex justify-center py-0.5 font-bold absolute top-0 w-full text-center mx-auto text_color"
            >
              {{ this.message }}</span
            >
            <span
              class="text_xs flex justify-center py-0.5 font-bold absolute bottom-0 w-full text-center mx-auto mt-1 text_color"
              >Bit Depth:
              {{ this.data.dbtm ? parseFloat(this.data.dbtm).toFixed(2) : 0 }}
              (ft)</span
            >
          </div>
          <canvas
            ref="right_casing"
            width="50"
            :height="casingHeight"
            :style="{ order: 3 }"
            @mousemove="casingMouseMoveHandler('right', $event)"
          ></canvas>
        </div>
      </div>
      <form class="opt___s">
        <div class="flex justify-center items-center">
          <input
            id="pillTrace"
            value="pill"
            class="tracer"
            name="tracer"
            type="radio"
            @input="startPillTrace"
          />
          <label for="pillTrace" class="ml-2 uppercase text_xs"
            >Pill Tracer</label
          >
        </div>
        <div class="flex justify-center items-center">
          <input
            id="lagTrace"
            value="lag"
            name="tracer"
            type="radio"
            @input="startPillTrace($event, true)"
          />
          <label for="lagTrace" class="ml-2 uppercase text_xs"
            >Lag Tracer</label
          >
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import * as d3 from "d3";
// import textures from 'textures';
import Service from "../../../api/services";
import { useReplay } from "../../../composable/useReplay.js";
import helperServices from "../../../helper-services";
import moment from "moment";
import { db } from "../../../db.js";

let svg;
export default {
  name: "hydraulics-calculator",
  props: {
    cardBackground: {
      type: String,
      default: "var(--widgetBg)",
    },
    widgetConfig: {
      type: Object,
    },
    widgetIndex: {
      type: Number,
    },
    widgetHeight: {
      type: Number,
      default: 700,
    },
    widgetWidth: {
      type: Number,
      default: 300,
    },
    widgetId: {
      type: String,
    },
    displayId: {
      type: String,
    },
    dry_pipe_length: {
      type: [Number, String],
    },
    slug_weight: {
      type: [Number, String],
    },
    slug_dry_pipe_length: {
      type: [Number, String],
    },
    slug_volume: {
      type: [Number, String],
    },
    annular_capacity: {
      type: [Number, String],
    },
    annular_slug_volume: {
      type: [Number, String],
    },
    washout_factor: {
      type: [Number, String],
    },
    drill_string_volume_left: {
      type: [Number, String],
    },
    stroke_required_displace_surface: {
      type: [Number, String],
    },
    height_spot_column: {
      type: [Number, String],
    },
    conversionFactors: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      c_dry_pipe_length: 0,
      c_slug_weight: 0,
      c_slug_dry_pipe_length: 0,
      c_slug_volume: 0,
      c_annular_capacity: 0,
      c_annular_slug_volume: 0,
      c_height_spot_column: 0,
      c_drill_string_volume_left: 0,
      isPillTracer: false,
      isAnnularTracer: false,
      current_strokes: 0,
      mnemonic_groups: {},
      position: 0,
      yScale: null,
      xScale: null,
      mouse_x: 0,
      mouse_y: 0,
      tool_popup: false,
      mudDensity: 0,
      // fields_required: [
      //   "hwdpannularvolume",
      //   "hwdpannularcapacity",
      //   "dc1annularvolume",
      //   "dc2annularvolume",
      //   "dpannularcapacity",
      //   "dc1volume",
      //   "dc2volume",
      //   "dc1annularcapacity",
      //   "dc2annularcapacity",
      //   "dc1capacity",
      //   "dc2capacity",
      //   "hwdpvolume",
      //   "dpvolume",
      //   "lagstrokes",
      //   "hwdpannularstrokes",
      //   "dpannularstrokes",
      //   "tot_stk",
      //   "dpstrokes",
      //   "hwdpstrokes",
      //   "dc1length",
      //   "dc2length",
      //   "dc1strokes",
      //   "dc2strokes",
      //   "dc2annularstrokes",
      //   "dplength",
      //   "hwdplength",
      //   "totalstrokes_2",
      //   "pumpoutput",
      //   "dpcapacity",
      //   "pumpedvolume",
      //   "dbtm",
      //   "dmea",
      //   "stringstrokes",
      //   "casingdc1length",
      //   "casingdc2length",
      //   "casingdplength",
      //   "hydrostaticpressure",
      //   "mud density",
      //   "drillstringvolume",
      //   "openholedplength",
      //   "openholehwdplength",
      //   "openholedc1length",
      //   "openholedc2length",
      //   "ohdc1annularcapacity",
      //   "ohdc2annularcapacity",
      //   "ohhwdpannularcapacity",
      //   "ohdpannularcapacity",
      //   "casinglength",
      //   "openholelength",
      //   "ohdc1annularstrokes",
      //   "ohdc2annularstrokes",
      //   "ohhwdpannularstrokes",
      //   "chdpannularstrokes",
      //   "chhwdpannularstrokes",
      //   "chdc1annularstrokes",
      //   "chdc2annularstrokes",
      //   "casinghwdplength",
      //   "casingdc1annularcapacity",
      //   "casingdc2annularcapacity",
      //   "casinghwdpannularcapacity",
      //   "casingdpannularcapacity",
      //   "ohdpannularstrokes",
      // ],
      fields_required: [
        "tot_stk",
        "dmea",
        "stringstrokes",
        "lagstrokes",
        "cyclestrokes",
        "csg1length",
        "csg2length",
        "csg3length",
        "linerlength",
        "openholelength",
        "openholedc1annularstrokes",
        "openholedc2annularstrokes",
        "openholehwdpannularstrokes",
        "openholedp1annularstrokes",
        "openholedp2annularstrokes",
        "linerdc1length",
        "openholedc1length",
        "openholedc2length",
        "openholehwdplength",
        "openholedp1length",
        "openholedp2length",
        "riserstrokes",
        "csg1strokes",
        "csg2strokes",
        "csg3strokes",
        "linerstrokes",
        "totallinerannularstrokes",
        "pumpoutput",
        "boosterpumpoutput",
        "ohdc1annularcapacity",
        "openholedc1annularcapacity",
        "dbtm",
        "ohdc2annularcapacity",
        "openholedc2annularcapacity",
        "ohhwdpannularcapacity",
        "openholehwdpannularcapacity",
        "openholedp1annularcapacity",
        "openholedp2annularcapacity",
        "openholestrokes",
        "totalopenholeannularstrokes",
        "linerdc2length",
        "linerhwdplength",
        "linerdp1length",
        "linerdp2length",
        "linerdp1annularstrokes",
        "linerdp2annularstrokes",
        "linerhwdpannularstrokes",
        "linerdc2annularstrokes",
        "totalcsg1annularstrokes",
        "totalcsg2annularstrokes",
        "totalcsg3annularstrokes",
        "linerdc1annularcapacity",
        "linerdc2annularcapacity",
        "linerdc1annularstrokes",
        "dc1length",
        "dc2length",
        "dp1length",
        "hwdplength",
        "linerhwdpannularcapacity",
        "linerdp1annularcapacity",
        "linerdp2annularcapacity",
        "csg3dc1length",
        "csg3dc2length",
        "csg3hwdplength",
        "csg3dp1length",
        "csg3dp2length",
        "csg3dp2annularstrokes",
        "csg3dp1annularstrokes",
        "csg3hwdpannularstrokes",
        "csg3dc2annularstrokes",
        "csg3dc1annularcapacity",
        "csg3dc1annularstrokes",
        "csg3dc2annularcapacity",
        "csg3hwdpannularcapacity",
        "csg3dp1annularcapacity",
        "csg3dp2annularcapacity",
        "csg2dc1length",
        "csg2dc2length",
        "csg2hwdplength",
        "csg2dp1length",
        "csg2dp2length",
        "csg2dp2annularstrokes",
        "csg2dp1annularstrokes",
        "csg2hwdpannularstrokes",
        "csg2dc2annularstrokes",
        "csg2dc1annularcapacity",
        "csg2dc1annularstrokes",
        "csg2dc2annularcapacity",
        "csg2hwdpannularcapacity",
        "csg2dp1annularcapacity",
        "csg2dp2annularcapacity",
        "csg1dc1length",
        "csg1dc2length",
        "csg1hwdplength",
        "csg1dp1length",
        "csg1dp2length",
        "csg1dp2annularstrokes",
        "csg1dp1annularstrokes",
        "csg1hwdpannularstrokes",
        "csg1dc2annularstrokes",
        "csg1dc1annularcapacity",
        "csg1dc1annularstrokes",
        "csg1dc2annularcapacity",
        "csg1hwdpannularcapacity",
        "csg1dp1annularcapacity",
        "csg1dp2annularcapacity",
        "riserlength",
        "totalriserannularstrokes",
        "riserdc1length",
        "riserdc2length",
        "riserhwdplength",
        "riserdp1length",
        "riserdp2length",
        "riserdp2annularstrokes",
        "riserdp1annularstrokes",
        "riserhwdpannularstrokes",
        "riserdc2annularstrokes",
        "riserdc1annularcapacity",
        "riserdc1strokes",
        "riserdc2annularcapacity",
        "riserhwdpannularcapacity",
        "riserdc1annularstrokes",
        "riserdp1annularcapacity",
        "riserdp2annularcapacity",
        "dp2strokes",
        "dp1strokes",
        "hwdpstrokes",
        "dc1strokes",
        "dc2strokes",
        "dp2capacity",
        "dp2stringcapacity",
        "dp1capacity",
        "dp1stringcapacity",
        "dp2length",
        "dp1volume",
        "dp1stringvolume",
        "dp2volume",
        "dp2stringvolume",
        "hwdpcapacity",
        "hwdpstringcapacity",
        "hwdpvolume",
        "hwdpstringvolume",
        "dc2capacity",
        "dc2volume",
        "dc1stringvolume",
        "dc2stringvolume",
        "dc1capacity",
        "dc1stringcapacity",
        "dc2stringcapacity",
        "mud density",
        "drillstringvolume",
      ],
      data: {},
      condition: 0,
      initial_strokes: 0,
      pill_depth: 0,
      pill_depth_annular: -1,
      trajectoryLogId: "",
      isPipeString: false,
      isNoData: false,
      pillTraces: false,
      total_volume_spotting_fluid: 0,
      spot_pill_strokes_required: 0,
      isLagDepth: false,
      slug_length: 0,
      slugVolume: 0,
      heightGained: 0,
      pressureGained: 0,
      volumeGained: 0,
      leftCasingCanvas: null,
      rightCasingCanvas: null,
      casingHeight: this.widgetHeight - 50,
      geometry: {},
      isWellMetrics: false,
      unitConversionFactors: {},
      copy_of_data: {},
      copy_of_data_initial: {},
      data_initial: {},
    };
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  async mounted() {
    let displayId = this.displayId;
    let displayDtls = this.$store.state.disp.displays[displayId];

    if (displayDtls) {
      //
      this.isWellMetrics = displayDtls.unit_conversion;
      this.wellId = displayDtls.wellId;
      this.wellboreId = displayDtls.wellboreId;
      this.logId = displayDtls.logId;
      this.status = displayDtls.status;
      this.logType = displayDtls.logType;
      this.logTypeId = displayDtls.logTypeId;
      this.max = displayDtls.max;
      this.table = displayDtls.table;
      this.numericValue = "";
      this.tooltipValue = "";
      this.topic = displayDtls.topicName;

      this.trajectoryLogId = displayDtls.trajectoryLogId;
      await this.getMinMaxRange();
      await this.getWellInformation();
      await this.getMenmonics(displayDtls);

      // }
    } else {
    }
    this.$store.subscribe(async (mutation, state) => {
      switch (mutation.type) {
        case "disp/setDisplay":
          if (mutation.payload.display == this.displayId) {
            if (this.$socket) {
              this.takeOffSocket();
            }
            this.isWellMetrics = mutation.payload.unit_conversion;

            this.wellId = mutation.payload.wellId;
            this.wellboreId = mutation.payload.wellboreId;
            this.logId = mutation.payload.logId;
            this.status = mutation.payload.status;
            this.logType = mutation.payload.logType;
            this.logTypeId = mutation.payload.logTypeId;
            this.max = mutation.payload.max;
            this.table = mutation.payload.table;
            this.numericValue = "";
            this.tooltipValue = "";
            this.trajectoryLogId = mutation.payload.trajectoryLogId;
            this.topic = mutation.payload.topicName;
            await this.getMinMaxRange();
            await this.getWellInformation();
            await this.getMenmonics();

            d3.select(`#hydraulics-calculator${this.widgetId}`)
              .selectAll("svg")
              .remove();

            this.isPillTracer = false;
            this.isAnnularTracer = false;
            this.current_strokes = 0;
            this.pill_depth = 0;
            this.pill_depth_annular = -1;
            this.buildHydraulicsCalculator();
          }
          break;
        case "rect/changSwabSearchUnitFactor":
          if(mutation.payload.displayId == this.displayId){
            // console.log('getSlugCalulationData_call')
            try{
              this.getSlugCalulationData();
            }
            catch(err){
              console.error(err);
            }
          }
          break; 
      }
    });
    this.casingHeight = this.widgetHeight - 50;
    this.leftCasingCanvas = this.$refs.left_casing;
    this.rightCasingCanvas = this.$refs.right_casing;

    this.buildHydraulicsCalculator();
  },
  unmounted() {
    //

    this.takeOffSocket();
  },
  beforeUnmount() {
    if (this.$socket) {
      this.takeOffSocket();
    }
  },
  methods: {
    converting_data() {
      // console.log("this.data", this.data, this.copy_of_data);
      this.copy_of_data = JSON.parse(JSON.stringify(this.data));
      this.copy_of_data_initial = JSON.parse(JSON.stringify(this.data_initial));
      try {
        for (let conversionKey in this.unitConversionFactors) {
          if (
            this.unitConversionFactors[conversionKey].category.toLowerCase() !=
            "miscellaneous"
          ) {
            if (this.data[conversionKey]) {
              this.data[conversionKey] *= parseFloat(
                this.unitConversionFactors[conversionKey].conversion_factor
              );
            }
            if (this.data_initial[conversionKey]) {
              this.data_initial[conversionKey] *= parseFloat(
                this.unitConversionFactors[conversionKey].conversion_factor
              );
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    casingMouseMoveHandler(val, event) {
      const rect =
        val == "left"
          ? this.leftCasingCanvas.getBoundingClientRect()
          : this.rightCasingCanvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      if (val == "left") {
        if ((x >= 10 && x >= 110) || (x && y >= 75 && y <= 114)) {
          this.isPipeString = false;
          this.tool_popup = true;
          this.mouse_x = event.clientX;
          this.mouse_y = event.clientY;
        } else {
          this.tool_popup = false;
          this.isPipeString = true;
        }
      } else {
        if ((x >= 10 && x >= 110) || (x && y >= 75 && y <= 114)) {
          this.isPipeString = false;
          this.tool_popup = true;
          this.mouse_x = event.clientX;
          this.mouse_y = event.clientY;
        } else {
          this.tool_popup = false;
          this.isPipeString = true;
        }
      }
    },
    async getMinMaxRange() {
      try {
        let result = null;
        if (this.logType == "time") {
          result = await Service.WellServices.getMinMaxTime({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          // console.log(
          //   "time zone difference : ",
          //   result.data.min,
          //   moment(result.data.min).unix() * 1000
          // );
          this.max = result.data.max;
          // result.data.min = moment(result.data.min).unix() * 1000;
          // result.data.max = moment(result.data.max).unix() * 1000;
        }

        return result;
      } catch (error) {
        console.error(error);
        return { min: 0, max: 1000 };
      }
    },
    // getCondition(current_strokes, remaining_strokes) {
    //   // When Current strokes <DrillPipe Strokes(dp strokes). (Calculated in string strokes)
    //   console.log(
    //     "getcondition 1",
    //     current_strokes,
    //     parseFloat(this.data.dpstrokes)
    //   );
    //   if (!this.isLagDepth) {
    //     if (current_strokes <= parseFloat(this.data.dpstrokes))
    //       this.condition = 1;
    //     // WhenDrill pipe strokes<current strokes < (drill pipe strokes+ HWDP Strokes),
    //     else if (
    //       parseFloat(this.data.dpstrokes) <= current_strokes &&
    //       current_strokes <=
    //         parseFloat(this.data.dpstrokes) + parseFloat(this.data.hwdpstrokes)
    //     )
    //       this.condition = 2;
    //     // When (Drill pipe strokes + HWDP Strokes)<Current strokes<string strokes (stringstrokes).
    //     else if (
    //       parseFloat(this.data.dc1length) > 0 &&
    //       parseFloat(this.data.dc2length) < 0.01 &&
    //       parseFloat(this.data.dpstrokes) + parseFloat(this.data.hwdpstrokes) <=
    //         current_strokes &&
    //       current_strokes <= parseFloat(this.data.stringstrokes)
    //     )
    //       this.condition = "3a";
    //     else if (
    //       parseFloat(this.data.dc1length) > 0 &&
    //       parseFloat(this.data.dc2length) > 0 &&
    //       parseFloat(this.data.dpstrokes) + parseFloat(this.data.hwdpstrokes) <=
    //         current_strokes &&
    //       current_strokes <=
    //         parseFloat(this.data.stringstrokes) -
    //           parseFloat(this.data.dc1strokes)
    //     )
    //       this.condition = "3b";
    //     else if (
    //       parseFloat(this.data.dc1length) > 0 &&
    //       parseFloat(this.data.dc2length) > 0 &&
    //       parseFloat(this.data.dpstrokes) +
    //         parseFloat(this.data.hwdpstrokes) +
    //         parseFloat(this.data.dc2strokes) <=
    //         current_strokes &&
    //       current_strokes <= parseFloat(this.data.stringstrokes)
    //     )
    //       this.condition = "3c";
    //     else
    //       this.getPillCalculationConditions(current_strokes, remaining_strokes);
    //   } else {
    //     this.getPillCalculationConditions(current_strokes, remaining_strokes);
    //   }

    //   return this.condition;
    // },
    getCondition(current_strokes, remaining_strokes) {
      // When Current strokes <DrillPipe Strokes(dp strokes). (Calculated in string strokes)
      // console.log(
      //   "getcondition 1",
      //   current_strokes,
      //   parseFloat(this.data.dpstrokes)
      // );
      if (!this.isLagDepth) {
        if (current_strokes <= parseFloat(this.data.dp2strokes))
          this.condition = 1;
        // WhenDrill pipe strokes<current strokes < (drill pipe strokes+ HWDP Strokes),
        else if (
          parseFloat(this.data.dp2strokes) < current_strokes &&
          current_strokes <
            (parseFloat(this.data.dp1strokes) + parseFloat(this.data.dp2strokes))
        )
          this.condition = 2;
        // When (Drill pipe strokes + HWDP Strokes)<Current strokes<string strokes (stringstrokes).
        else if (
          (parseFloat(this.data.dp1strokes) + parseFloat(this.data.dp2strokes)) <=
            current_strokes &&
          current_strokes <= (parseFloat(this.data.dp1strokes) + parseFloat(this.data.dp2strokes) + parseFloat(this.data.hwdpstrokes))
        )
          this.condition = "3a";
        else if (
          (parseFloat(this.data.dp1strokes) + parseFloat(this.data.dp2strokes) + parseFloat(this.data.hwdpstrokes))<=
            current_strokes &&
          current_strokes <=
            parseFloat(this.data.stringstrokes) -
              parseFloat(this.data.dc1strokes)
        )
          this.condition = "3b";
        else if (
          (parseFloat(this.data.dp1strokes) + parseFloat(this.data.dp2strokes) + parseFloat(this.data.hwdpstrokes) +
            parseFloat(this.data.dc2strokes)) <=
            current_strokes &&
          current_strokes <= parseFloat(this.data.stringstrokes)
        )
          this.condition = "3c";
        else
          this.getPillCalculationConditions(current_strokes, remaining_strokes);
      } else {
        this.getPillCalculationConditions(current_strokes, remaining_strokes);
      }

      if(!this.condition && this.pill_depth_annular >= 0){  // TO HIDE THE PILL AFTER REACHING TO THE TOP
        if( 
          (!this.isLagDepth && this.data?.cyclestrokes && this.current_strokes >= this.data.cyclestrokes) || 
          (this.isLagDepth && this.data?.lagstrokes && this.current_strokes >= this.data.lagstrokes)
        ){
          this.pill_depth_annular = -1;
        }
      }

      return this.condition;
    },
    dblClick(index, config) {
      config = this.widgetConfig;
      if (
        this.$store.state.rect.selectedWidgetProps &&
        this.$store.state.rect.selectedWidgetProps.widgetId
      ) {
        if (
          config.widgetId == this.$store.state.rect.selectedWidgetProps.widgetId
        ) {
          this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
        } else {
        }
      } else {
        this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
      }
      this.$store.dispatch("rect/toggleRightSideBar", {
        id: this.widgetIndex,
        value: this.widgetConfig,
      });
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      //   e.stopPropagation();
    },
    showPill(ctx) {
      // this.pill_depth_annular = 12000;
      // this.pill_depth = 1200;
      // console.log("called show pill : ", this.pill_depth_annular);
      if (
        this.pill_depth_annular > 0 &&
        this.pill_depth_annular < this.data.dbtm
      ) {
        // svg
        //   .append("circle")
        //   .attr("id", "orange1-circle")
        //   .style("stroke", "orange")
        //   .style("stroke-width", "5")
        //   .style("fill", "transparent")
        //   .attr("r", 10)
        //   .attr("cx", this.xScale(5))
        //   .style("filter", "drop-shadow(orange 0px 3px 2px)")
        //   .attr("cy", this.yScale(this.pill_depth_annular));
        // svg
        //   .append("circle")
        //   .attr("id", "orange2-circle")
        //   .style("stroke", "orange")
        //   .style("stroke-width", "5")
        //   .style("fill", "transparent")
        //   .attr("r", 10)
        //   .attr("cx", this.xScale(25))
        //   .style("filter", "drop-shadow(orange 0px 3px 2px)")
        //   .attr("cy", this.yScale(this.pill_depth_annular));
        let y = this.yScale(this.pill_depth_annular);
        ctx.beginPath();
        ctx.strokeStyle = "orange";
        ctx.lineWidth = 6;
        ctx.arc(this.xScale(9.5), y, 8, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.closePath();
        ctx.beginPath();
        ctx.strokeStyle = "orange";
        ctx.lineWidth = 6;
        ctx.arc(this.xScale(20.5), y, 8, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.closePath();
      } else {
        // console.log("else");
        // svg.select("#orange1-circle").remove();
        // svg.select("#orange1-circle").remove();
      }
      if (!this.isLagDepth) {
        if (this.pill_depth > 0 && this.pill_depth < this.data.dbtm) {
          let y = this.yScale(this.pill_depth);
          // svg
          //   .append("circle")
          //   .attr("id", "green-circle")
          //   .style("stroke", "green")
          //   .style("fill", "transparent")
          //   .style("stroke-width", "5")
          //   .style("filter", "drop-shadow(green 0px -2px 2px)")
          //   .attr("r", 8)
          //   .attr("cx", this.xScale(15))
          //   .attr("cy", this.yScale(this.pill_depth));
          ctx.beginPath();
          ctx.lineWidth = 6;
          ctx.strokeStyle = "green";
          ctx.arc(this.xScale(15), y, 8, 0, 2 * Math.PI);
          ctx.stroke();
          ctx.closePath();
        } else {
          // svg.select("#green-circle").remove();
        }
      }
    },
    async startPillTrace(event, isLagDepth) {
      this.condition = 0;
      this.pillTraces = event.target.checked;
      this.isLagDepth = isLagDepth;
      this.current_strokes = 0;
      // svg.select("#green-circle").remove();
      // svg.select("#orange1-circle").remove();
      // svg.select("#orange1-circle").remove();
      if (
        (this.status == "UPDATING" || this.status == 0) &&
        !this.widgetConfig.isReplay
      ) {
        if (this.$socket) {
          await this.takeOffSocket();
        }
        this.getRealtimeData();
        this.showPill();
      }
      // if (event.target.checked) {
      //     if (this.status == "UPDATING" || this.status == 0) {
      //         if (this.$socket) {
      //             await this.takeOffSocket()
      //         }
      //         this.getRealtimeData();
      //         this.showPill()
      //     }
      // } else {
      //     if (this.$socket) {
      //         await this.takeOffSocket()
      //     }

      // }
    },
    // getPillCalculationConditions(current_strokes, remaining_strokes) {
    //   // CASING LENGTH > 0, Open Hole Length > 0 ,
    //   // CURRENT STROKES <  (OH DC1 Annular strokes + OH DC2 Annular Strokes + OH HWDP Annular strokes + OH DP Annular strokes.)
    //   // this.current_strokes is also current strokes across annulus
    //   // if (this.data.isLagDepth) this.current_strokes = this.current_strokes;
    //   // else
    //   //   this.current_strokes = this.current_strokes - this.data.stringstrokes;

    //   let current_strokes_annulus = 0;
    //   //(CUMMULATIVETOTAL STROKES - INITIAL STROKES).
    //   if (current_strokes > Number(this.data.stringstrokes))
    //     current_strokes_annulus =
    //       Number(current_strokes) -
    //       (this.isLagDepth ? 0 : Number(this.data.stringstrokes));
    //   else
    //     current_strokes_annulus =
    //       Number(current_strokes) -
    //       (Number(0) + (this.isLagDepth ? 0 : Number(this.data.stringstrokes)));

    //   let current_pill_depth = 0;
    //   console.log(
    //     "~~~~~~~~~~~ condition current strokes annulus ~~~~~~~~~~~~~~ ",
    //     this.data.tot_stk,
    //     current_strokes_annulus,
    //     current_strokes,
    //     this.data.casinglength >= 0,
    //     this.data.openholelength > 0,
    //     current_strokes <
    //       this.data.ohdc1annularstrokes +
    //         this.data.ohdc2annularstrokes +
    //         this.data.ohhwdpannularstrokes +
    //         this.data.ohdpannularstrokes
    //   );
    //   current_strokes = current_strokes_annulus;

    //   if (
    //     this.data.casinglength >= 0 &&
    //     this.data.openholelength > 0 &&
    //     current_strokes <
    //       this.data.ohdc1annularstrokes +
    //         this.data.ohdc2annularstrokes +
    //         this.data.ohhwdpannularstrokes +
    //         this.data.ohdpannularstrokes
    //   ) {
    //     console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 2);
    //     if (
    //       this.data.casingdc1length >= 0 &&
    //       this.data.openholedc1length >= 0 &&
    //       this.data.openholedc2length >= 0 &&
    //       this.data.openholehwdplength >= 0 &&
    //       this.data.openholedplength >= 0
    //     ) {
    //       if (current_strokes <= this.data.ohdc1annularstrokes) {
    //         let annular_volume_accross_dc1 =
    //           current_strokes * this.data.pumpoutput;
    //         console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 3);
    //         if (
    //           this.data.chdpannularstrokes +
    //             this.data.ohdpannularstrokes +
    //             this.data.ohhwdpannularstrokes +
    //             this.data.ohdc2annularstrokes +
    //             this.data.chhwdpannularstrokes +
    //             this.data.chdc1annularstrokes +
    //             this.data.chdc2annularstrokes <=
    //           remaining_strokes
    //         ) {
    //           console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 4);
    //           this.condition = 5;
    //           let oh_length_accross_dc1 =
    //             annular_volume_accross_dc1 / this.data.ohdc1annularcapacity;
    //           this.pill_depth_annular = this.data.dbtm - oh_length_accross_dc1;
    //         }
    //       }
    //     }
    //     // OH DC1 Annular strokes < Current stokes or Cal Current Strokes across Annulus <= OH DC1 Annular strokes + OH DC2 Annular strokes
    //     if (
    //       this.data.ohdc1annularstrokes < current_strokes &&
    //       current_strokes <=
    //         this.data.ohdc1annularstrokes + this.data.ohdc2annularstrokes
    //     ) {
    //       console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 5);
    //       let oh_dc2_curr_annular_strokes =
    //         current_strokes - this.data.ohdc1annularstrokes;
    //       let annular_volume_accross_dc2 =
    //         oh_dc2_curr_annular_strokes * this.data.pumpoutput;
    //       // (CH DP Annular strokes + CH HWDP Annular Strokes+ CH DC2 Annular strokes) < Remaining strokes to surface.
    //       if (
    //         this.data.chdpannularstrokes +
    //           this.data.ohdpannularstrokes +
    //           this.data.ohhwdpannularstrokes +
    //           this.data.chhwdpannularstrokes +
    //           this.data.chdc2annularstrokes <
    //         remaining_strokes
    //       ) {
    //         console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 6);
    //         this.condition = 6;
    //         let oh_length_accross_dc2 =
    //           annular_volume_accross_dc2 / this.data.ohdc2annularcapacity;
    //         this.pill_depth_annular =
    //           this.data.dbtm -
    //           (oh_length_accross_dc2 + this.data.openholedc1length);
    //       }
    //     }

    //     // OH DC1 Annular strokes + OH DC2 Annular strokes < Current stokes or Cal Current Strokes across Annulus <= OH DC1 Annular strokes + OH DC2 Annular strokes + OH HWDP Annular strokes.
    //     if (
    //       this.data.ohdc1annularstrokes + this.data.ohdc2annularstrokes <
    //         current_strokes &&
    //       current_strokes <=
    //         this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.ohhwdpannularstrokes
    //     ) {
    //       console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 7);
    //       let oh_hwdp_curr_annular_strokes =
    //         current_strokes -
    //         (this.data.ohdc1annularstrokes + this.data.ohdc2annularstrokes);
    //       let annular_volume_accross_hwdp =
    //         oh_hwdp_curr_annular_strokes * this.data.pumpoutput;
    //       // (CH DP Annular strokes+ CH HWDP Annular Strokes) < Remaining strokes to surface
    //       if (
    //         this.data.chdpannularstrokes +
    //           this.data.ohdpannularstrokes +
    //           this.data.chhwdpannularstrokes <
    //         remaining_strokes
    //       ) {
    //         console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 8);
    //         this.condition = 7;
    //         let oh_length_accross_hwdp =
    //           annular_volume_accross_hwdp / this.data.ohhwdpannularcapacity;
    //         this.pill_depth_annular =
    //           this.data.dbtm -
    //           (oh_length_accross_hwdp +
    //             this.data.openholedc2length +
    //             this.data.openholedc1length);
    //       }
    //     }

    //     // OH DC1 Annular strokes + OH DC2 Annular strokes + OH HWDP Annular Strokes < Current stokes or Cal Current Strokes across Annulus <= OH DC1 Annular strokes + OH DC2 Annular strokes+ OH HWDP Annular strokes + OH DP Annular strokes
    //     if (
    //       this.data.ohdc1annularstrokes +
    //         this.data.ohdc2annularstrokes +
    //         this.data.ohhwdpannularstrokes <
    //         current_strokes &&
    //       current_strokes <=
    //         this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.ohhwdpannularstrokes +
    //           this.data.ohdpannularstrokes
    //     ) {
    //       console.log(
    //         "~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ",
    //         9,
    //         this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.ohhwdpannularstrokes <
    //           current_strokes,
    //         current_strokes <=
    //           this.data.ohdc1annularstrokes +
    //             this.data.ohdc2annularstrokes +
    //             this.data.ohhwdpannularstrokes +
    //             this.data.ohdpannularstrokes
    //       );

    //       let oh_dp_curr_annular_strokes =
    //         current_strokes -
    //         (this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.ohhwdpannularstrokes);
    //       let annular_volume_accross_dp =
    //         oh_dp_curr_annular_strokes * this.data.pumpoutput;
    //       if (this.data.chdpannularstrokes <= remaining_strokes) {
    //         console.log(
    //           "~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ",
    //           10,
    //           this.data.chdpannularstrokes <= remaining_strokes
    //         );

    //         this.condition = 8;
    //         let oh_length_accross_dp =
    //           annular_volume_accross_dp / this.data.ohdpannularcapacity;
    //         this.pill_depth_annular =
    //           this.data.dbtm -
    //           (oh_length_accross_dp +
    //             this.data.openholehwdplength +
    //             this.data.openholedc2length +
    //             this.data.openholedc1length);
    //       }
    //     }
    //   }

    //   // ************************************************ Casing Hole Conditions ************************************************
    //   if (this.data.casinglength > 0 && this.data.openholelength >= 0) {
    //     console.log(
    //       "~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ",
    //       11,
    //       this.data.casingdc1length > 0,
    //       this.data.openholedc1length >= 0,
    //       this.data.casingdc2length >= 0,
    //       this.data.casinghwdplength >= 0,
    //       this.data.casingdplength >= 0,
    //       this.data.openholedplength >= 0
    //     );
    //     console.log(
    //       "~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ",
    //       11,
    //       this.data.casingdc1length,
    //       this.data.openholedc1length,
    //       this.data.casingdc2length,
    //       this.data.casinghwdplength,
    //       this.data.casingdplength,
    //       this.data.openholedplength
    //     );
    //     //Casing DC1 length > 0, Open Hole DC1 Length ≥ 0 && Casing DC2 Length >= 0 , Casing HWDP Length >= 0 & Casing DP Length Open Hole DP Length ≥ 0
    //     if (
    //       this.data.casingdc1length > 0 &&
    //       this.data.openholedc1length >= 0 &&
    //       this.data.casingdc2length >= 0 &&
    //       this.data.casinghwdplength >= 0 &&
    //       this.data.casingdplength >= 0 &&
    //       this.data.openholedplength >= 0
    //     ) {
    //       console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 11.1);
    //       // OH DC1 Annular strokes < Current stokes or Cal Current Strokes across Annulus <= OH DC1 Annular strokes + CH DC1 Annular Strokes.
    //       if (
    //         this.data.ohdc1annularstrokes < current_strokes &&
    //         current_strokes <=
    //           this.data.ohdc1annularstrokes + this.data.chdc1annularstrokes
    //       ) {
    //         console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 12);
    //         let ch_dc1_curr_annular_strokes =
    //           current_strokes - this.data.ohdc1annularstrokes;

    //         let annular_volume_accross_ch_dc1 =
    //           ch_dc1_curr_annular_strokes * this.data.pumpoutput;
    //         // CH DP Annular strokes + CH HWDP Annular Strokes+ CH DC2 Annular strokes) ≤ Remaining annular strokes to surface
    //         if (
    //           this.data.chdpannularstrokes +
    //             this.data.chhwdpannularstrokes +
    //             this.data.chdc2annularstrokes <=
    //           remaining_strokes
    //         ) {
    //           console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 13);
    //           this.condition = 9;
    //           let ch_length_across_dc1 =
    //             annular_volume_accross_ch_dc1 /
    //             this.data.casingdc1annularcapacity;
    //           this.pill_depth_annular =
    //             this.data.dbtm -
    //             ch_length_across_dc1 +
    //             this.data.openholedc1length;
    //         }
    //       }
    //     }

    //     // Casing DC2 length > 0, Open Hole DC2 Length ≥ 0 && (OH DC1 Annular strokes + OH DC2 Annular strokes)  < Current strokes or Cal Current Strokes across Annulus <= (OH DC1 Annular strokes + OH DC2 Annular strokes+ CH DC1 Annular strokes + CH DC2 Annular strokes).
    //     console.log(
    //       "~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ *** ",
    //       11,
    //       this.data.casingdc2length > 0,
    //       this.data.openholedc2length >= 0,
    //       this.data.ohdc1annularstrokes + this.data.ohdc2annularstrokes <
    //         current_strokes,
    //       current_strokes <=
    //         this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.chdc1annularstrokes +
    //           this.data.chdc2annularstrokes
    //     );
    //     console.log(
    //       "~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ *** ",
    //       11,
    //       this.data.casingdc2length,
    //       this.data.openholedc2length,
    //       this.data.ohdc1annularstrokes + this.data.ohdc2annularstrokes,
    //       current_strokes,
    //       this.data.ohdc1annularstrokes +
    //         this.data.ohdc2annularstrokes +
    //         this.data.chdc1annularstrokes +
    //         this.data.chdc2annularstrokes
    //     );

    //     if (
    //       this.data.casingdc2length > 0 &&
    //       this.data.openholedc2length >= 0 &&
    //       this.data.ohdc1annularstrokes + this.data.ohdc2annularstrokes <
    //         current_strokes &&
    //       current_strokes <=
    //         this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.chdc1annularstrokes +
    //           this.data.chdc2annularstrokes
    //     ) {
    //       console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 14);
    //       let ch_dc2_annular_strokes =
    //         current_strokes -
    //         (this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.chdc1annularstrokes);
    //       let annular_volume_accross_ch_dp2 =
    //         ch_dc2_annular_strokes * this.data.pumpoutput;
    //       // (CH DP Annular strokes + CH HWDP Annular Strokes) <= Remaining strokes to surface
    //       if (
    //         this.data.chdpannularstrokes + this.data.chhwdpannularstrokes <=
    //         remaining_strokes
    //       ) {
    //         console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 15);
    //         this.condition = 10;
    //         let ch_length_across_dc2 =
    //           annular_volume_accross_ch_dp2 /
    //           this.data.casingdc2annularcapacity;
    //         this.pill_depth_annular =
    //           this.data.dbtm -
    //           (ch_length_across_dc2 +
    //             this.data.openholedc2length +
    //             this.data.openholedc1length);
    //       }
    //     }

    //     console.log(
    //       "******** conditions below below 11 ************** ",
    //       this.data.casinghwdplength > 0,
    //       this.data.openholehwdplength >= 0,
    //       this.data.ohdc1annularstrokes +
    //         this.data.ohdc2annularstrokes +
    //         this.data.ohhwdpannularstrokes <
    //         current_strokes,
    //       current_strokes <=
    //         this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.ohhwdpannularstrokes +
    //           this.data.chdc1annularstrokes +
    //           this.data.chdc2annularstrokes +
    //           this.data.chhwdpannularstrokes
    //     );
    //     console.log(
    //       "******** conditions below below 11 ************** ",
    //       this.data.casinghwdplength,
    //       this.data.openholehwdplength,
    //       this.data.ohdc1annularstrokes +
    //         this.data.ohdc2annularstrokes +
    //         this.data.ohhwdpannularstrokes,
    //       current_strokes,
    //       this.data.ohdc1annularstrokes +
    //         this.data.ohdc2annularstrokes +
    //         this.data.ohhwdpannularstrokes +
    //         this.data.chdc1annularstrokes +
    //         this.data.chdc2annularstrokes +
    //         this.data.chhwdpannularstrokes
    //     );
    //     // Casing HWDP Length > 0, Open Hole HWDP Length ≥ 0 && OH DC1 Annular strokes + OH DC2 Annular strokes + OH HWDP Annular strokes < Current stokes or Cal Current Strokes across Annulus <= OH DC1 Annular strokes + OH DC2 Annular strokes+ OH HWDP Annular strokes+ CH DC1 Annular strokes + CH DC2 Annular strokes + CH HWDP Annular strokes
    //     if (
    //       this.data.casinghwdplength > 0 &&
    //       this.data.openholehwdplength >= 0 &&
    //       this.data.ohdc1annularstrokes +
    //         this.data.ohdc2annularstrokes +
    //         this.data.ohhwdpannularstrokes <
    //         current_strokes &&
    //       current_strokes <=
    //         this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.ohhwdpannularstrokes +
    //           this.data.chdc1annularstrokes +
    //           this.data.chdc2annularstrokes +
    //           this.data.chhwdpannularstrokes
    //     ) {
    //       console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 16);
    //       let ch_hwdp_annular_strokes =
    //         current_strokes -
    //         (this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.ohhwdpannularstrokes +
    //           this.data.chdc1annularstrokes +
    //           this.data.chdc2annularstrokes);

    //       let ch_hwdp_annular_volume =
    //         ch_hwdp_annular_strokes * this.data.pumpoutput;

    //       if (this.data.chdpannularstrokes <= remaining_strokes) {
    //         console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 17);
    //         this.condition = 11;
    //         let ch_length_across_hwdp =
    //           ch_hwdp_annular_volume / this.data.casinghwdpannularcapacity;
    //         this.pill_depth_annular =
    //           this.data.dbtm -
    //           (ch_length_across_hwdp +
    //             this.data.openholehwdplength +
    //             this.data.openholedc2length +
    //             this.data.openholedc1length);
    //       }
    //     }

    //     console.log(
    //       "************ cond 11. >>>>>>>>>>  ",
    //       this.data.casingdplength > 0,
    //       this.data.openholedplength >= 0,
    //       this.data.ohdc1annularstrokes +
    //         this.data.ohdc2annularstrokes +
    //         this.data.ohhwdpannularstrokes +
    //         this.data.ohdpannularstrokes <
    //         current_strokes,
    //       current_strokes <=
    //         this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.ohhwdpannularstrokes +
    //           this.data.ohdpannularstrokes +
    //           this.data.chdc1annularstrokes +
    //           this.data.chdc2annularstrokes +
    //           this.data.chhwdpannularstrokes +
    //           this.data.chdpannularstrokes
    //     );
    //     console.log(
    //       "************ cond 11. >>>>>>>>>>  ",
    //       this.data.casingdplength,
    //       this.data.openholedplength,
    //       this.data.ohdc1annularstrokes +
    //         this.data.ohdc2annularstrokes +
    //         this.data.ohhwdpannularstrokes +
    //         this.data.ohdpannularstrokes,
    //       current_strokes,
    //       this.data.ohdc1annularstrokes,
    //       this.data.ohdc2annularstrokes,
    //       this.data.ohhwdpannularstrokes,
    //       this.data.ohdpannularstrokes,
    //       this.data.chdc1annularstrokes,
    //       this.data.chdc2annularstrokes,
    //       this.data.chhwdpannularstrokes,
    //       this.data.chdpannularstrokes
    //     );
    //     // Casing DP Length > 0, Open Hole DP Length ≥ 0 && OH DC1 Annular strokes + OH DC2 Annular strokes + OH HWDP Annular strokes + OH DP Annular strokes < Current stokes or Cal Current Strokes across Annulus < (OH DC1 Annular strokes + OH DC2 Annular strokes+ OH HWDP Annular strokes+ OH DP Annular strokes + CH DC1 Annular strokes + CH DC2 Annular strokes + CH HWDP Annular strokes + CH DP Annular strokes.
    //     if (
    //       this.data.casingdplength > 0 &&
    //       this.data.openholedplength >= 0 &&
    //       this.data.ohdc1annularstrokes +
    //         this.data.ohdc2annularstrokes +
    //         this.data.ohhwdpannularstrokes +
    //         this.data.ohdpannularstrokes <
    //         current_strokes &&
    //       current_strokes <=
    //         this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.ohhwdpannularstrokes +
    //           this.data.ohdpannularstrokes +
    //           this.data.chdc1annularstrokes +
    //           this.data.chdc2annularstrokes +
    //           this.data.chhwdpannularstrokes +
    //           this.data.chdpannularstrokes
    //     ) {
    //       console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 18);
    //       let ch_dp_annular_strokes =
    //         current_strokes -
    //         (this.data.ohdc1annularstrokes +
    //           this.data.ohdc2annularstrokes +
    //           this.data.ohhwdpannularstrokes +
    //           this.data.chdc1annularstrokes +
    //           this.data.chdc2annularstrokes +
    //           this.data.chhwdpannularstrokes +
    //           this.data.ohdpannularstrokes);

    //       let annular_volume_accross_ch_dp =
    //         ch_dp_annular_strokes * this.data.pumpoutput;

    //       if (this.data.chdpannularstrokes >= remaining_strokes) {
    //         console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 19);
    //         this.condition = 12;
    //         let ch_length_across_dp =
    //           annular_volume_accross_ch_dp / this.data.casingdpannularcapacity;
    //         this.pill_depth_annular =
    //           this.data.dbtm -
    //           (ch_length_across_dp +
    //             this.data.openholedplength +
    //             this.data.openholehwdplength +
    //             this.data.openholedc2length +
    //             this.data.openholedc1length);
    //       }
    //     }
    //   }
    //   return this.condition;
    // },

    getPillCalculationConditions(current_strokes, remaining_strokes) {
   
      let current_strokes_annulus = 0;
      //(CUMMULATIVETOTAL STROKES - INITIAL STROKES).
      if (current_strokes > Number(this.data.stringstrokes))
        current_strokes_annulus =
          Number(current_strokes) -
          (this.isLagDepth ? 0 : Number(this.data.stringstrokes));
      else
        current_strokes_annulus =
          Number(current_strokes) -
          (Number(0) + (this.isLagDepth ? 0 : Number(this.data.stringstrokes)));

      let current_pill_depth = 0;
      current_strokes = current_strokes_annulus;

      // 1.0
      if((this.data.csg1length >= 0 || 
        this.data.csg2length >= 0 ||
        this.data.csg3length >= 0 ||
        this.data.linerlength >= 0
        ) &&
        this.data.openholelength > 0 
      ){
        if(current_strokes <= 
          (this.data.openholedc1annularstrokes +
          this.data.openholedc2annularstrokes +
          this.data.openholehwdpannularstrokes +
          this.data.openholedp1annularstrokes +
          this.data.openholedp2annularstrokes)
        ){

          // Current strokes are across OH DC1.
          if (   
            (this.data.csg1length >= 0 || 
              this.data.csg2length >= 0 ||
              this.data.csg3length >= 0 ||
              this.data.linerdc1length >= 0
            ) &&
            this.data.openholedc1length > 0 &&
            this.data.openholedc2length >= 0 &&
            this.data.openholehwdplength >= 0 &&
            this.data.openholedp1length >= 0 &&
            this.data.openholedp2length >= 0
          ){


            // totalriserannularstrokes --- riserstrokes ---- TOTAL RISER ANNULAR STROKES
            // totalcsg1annularstrokes --- csg1strokes ---- TOTAL CH1 Annular strokes
            // totalcsg2annularstrokes --- csg2strokes ---- TOTAL CH2 Annular strokes
            // totalcsg3annularstrokes --- csg3strokes ----  TOTAL CH3 Annular strokes
            // totallinerannularstrokes --- linerstrokes ----  Total Liner Annular strokes
            // totalopenholeannularstrokes --- openholestrokes ---- TOTAL OH Annular strokes 

            if(current_strokes <= this.data.openholedc1annularstrokes &&
              (this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes + 
              this.data.totalcsg2annularstrokes + 
              this.data.totalcsg3annularstrokes + 
              this.data.totallinerannularstrokes ) <= remaining_strokes
            ){
              let annular_volume_accross_dc1 = current_strokes * this.data.pumpoutput;
              this.condition = 5;
              let oh_length_accross_dc1 =
                annular_volume_accross_dc1 / this.data.openholedc1annularcapacity;
              this.pill_depth_annular = this.data.dbtm - oh_length_accross_dc1;  // Current pill depth= [DBTM – OH Length of pill across (DC1)]
            }

          }

         // Current strokes is across OH DC2.
          if (
            (this.data.openholedc1annularstrokes < current_strokes) &&
            (current_strokes <=
              (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes))
          ) {
            let oh_dc2_curr_annular_strokes = current_strokes - this.data.openholedc1annularstrokes;
            let annular_volume_accross_dc2 = oh_dc2_curr_annular_strokes * this.data.pumpoutput;
            this.condition = 6;
            let oh_length_accross_dc2 =
              annular_volume_accross_dc2 / this.data.openholedc2annularcapacity;
            this.pill_depth_annular =
              this.data.dbtm -
              (oh_length_accross_dc2 + this.data.openholedc1length);
          }


          // Current strokes are across OH HWDP.
          if((this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes) <=
            current_strokes &&
            current_strokes <=
            (this.data.openholedc1annularstrokes +
            this.data.openholedc2annularstrokes +
            this.data.openholehwdpannularstrokes)
          ){
            let oh_hwdp_curr_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes);
            let annular_volume_accross_hwdp = oh_hwdp_curr_annular_strokes * this.data.pumpoutput;
            this.condition = 7;
            let oh_length_accross_hwdp =
              annular_volume_accross_hwdp / this.data.openholehwdpannularcapacity;
            this.pill_depth_annular =
              this.data.dbtm -
              (oh_length_accross_hwdp +
                this.data.openholedc2length +
                this.data.openholedc1length);
          }



          // Current strokes is across OH DP1.
          if((this.data.openholedc1annularstrokes +
            this.data.openholedc2annularstrokes +
            this.data.openholehwdpannularstrokes) <=
            current_strokes &&
            current_strokes <=
            (this.data.openholedc1annularstrokes +
              this.data.openholedc2annularstrokes +
              this.data.openholehwdpannularstrokes +
              this.data.openholedp1annularstrokes)
          ){
            let oh_dp_curr_annular_strokes =
              current_strokes -
              (this.data.openholedc1annularstrokes +
                this.data.openholedc2annularstrokes +
                this.data.openholehwdpannularstrokes);
            let annular_volume_accross_dp =
              oh_dp_curr_annular_strokes * this.data.pumpoutput;

            this.condition = 8;
            let oh_length_accross_dp = annular_volume_accross_dp / this.data.openholedp1annularcapacity;
            this.pill_depth_annular =
              this.data.dbtm -
              (oh_length_accross_dp +
                this.data.openholehwdplength +
                this.data.openholedc2length +
                this.data.openholedc1length);
          }


           // Current strokes is across OH DP2.
           if((this.data.openholedc1annularstrokes +
            this.data.openholedc2annularstrokes +
            this.data.openholehwdpannularstrokes + 
            this.data.openholedp1annularstrokes) <=
            current_strokes &&
            current_strokes <=
            (this.data.openholedc1annularstrokes +
              this.data.openholedc2annularstrokes +
              this.data.openholehwdpannularstrokes +
              this.data.openholedp1annularstrokes +
              this.data.openholedp2annularstrokes
            )
          ){
            let oh_dp_curr_annular_strokes =
              current_strokes -
              (this.data.openholedc1annularstrokes +
                this.data.openholedc2annularstrokes +
                this.data.openholehwdpannularstrokes +
                this.data.openholedp1annularstrokes);
            let annular_volume_accross_dp =
              oh_dp_curr_annular_strokes * this.data.pumpoutput;

            this.condition = 9;
            let oh_length_accross_dp = annular_volume_accross_dp / this.data.openholedp2annularcapacity;
            this.pill_depth_annular =
              this.data.dbtm -
              (oh_length_accross_dp +
                this.data.openholedp1length +
                this.data.openholehwdplength +
                this.data.openholedc2length +
                this.data.openholedc1length);
          }
          
        }
      }

      // 2.0
      //Current strokes is across Liner DC1.
      if(this.data.linerlength > 0 && 
        this.data.totalopenholeannularstrokes < current_strokes &&
        current_strokes <= (this.data.totalopenholeannularstrokes + 
          this.data.totallinerannularstrokes)
      ){

        //Open Hole DC1 Length ≥ 0 && Liner DC1 Length >0, &&   --- 2.1.1
        if(this.data.openholedc1length >= 0 && 
          this.data.linerdc1length > 0 &&
          this.data.linerdc2length >= 0 &&
          this.data.linerhwdplength >= 0 &&
          this.data.linerdp1length >= 0 &&
          this.data.linerdp2length >= 0
        ){
          let ch_dc1_curr_annular_strokes =
              current_strokes - this.data.openholedc1annularstrokes;

          let annular_volume_accross_ch_dc1 =
            ch_dc1_curr_annular_strokes * this.data.pumpoutput;

          // if(this.data.linerdp2annularstrokes + ) -----2.1.2  //  Liner Dp1 Annular Strokes ? Liner HWDP Annular strokes  ?
          if(( this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes + 
              this.data.totalcsg2annularstrokes + 
              this.data.totalcsg3annularstrokes +
              this.data.linerdp2annularstrokes + 
              this.data.linerdp1annularstrokes + 
              this.data.linerhwdpannularstrokes +
              this.data.linerdc2annularstrokes
            ) <= remaining_strokes &&
            remaining_strokes <= 
            (this.data.totalriserannularstrokes +
              this.data.totalcsg1annularstrokes + 
              this.data.totalcsg2annularstrokes + 
              this.data.totalcsg3annularstrokes + 
              this.data.totallinerannularstrokes
            )
          ){
            this.condition = 10;
            let liner_length_pill_across_dc1 = annular_volume_accross_ch_dc1/this.data.linerdc1annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (liner_length_pill_across_dc1 + this.data.openholedc1length);
          }
        }


        //Liner DC2 Length >0, &&  -------- 2.2.1
        if(this.data.linerdc2length > 0 && 
          this.data.linerdc1length >= 0 &&
          this.data.linerhwdplength >= 0 &&
          this.data.linerdp1length >= 0 &&
          this.data.linerdp2length >= 0
        ){
          let liner_dc2_annular_strokes =
            current_strokes -
            (this.data.openholedc1annularstrokes +
              this.data.openholedc2annularstrokes +
              this.data.linerdc1annularstrokes); // linerdc1annularstrokes----------- ?
          let liner_dc2_annular_volume = liner_dc2_annular_strokes * this.data.pumpoutput;
          
          // 2.2.2
          // Total Riser Annular strokes + Total CSG1 Annular strokes + Total CSG2 Annular strokes + Total CSG3 Annular strokes+ Liner Dp2 Annular strokes + Liner Dp1 Annular Strokes+ Liner HWDP Annular strokes 
          if((this.data.totalriserannularstrokes + 
            this.data.totalcsg1annularstrokes + 
            this.data.totalcsg2annularstrokes + 
            this.data.totalcsg3annularstrokes + 
            this.data.linerdp2annularstrokes + 
            this.data.linerdp1annularstrokes + 
            this.data.linerhwdpannularstrokes) <=
            remaining_strokes && 
            remaining_strokes <= 
            (
              this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes + 
              this.data.totalcsg2annularstrokes + 
              this.data.totalcsg3annularstrokes + 
              this.data.linerdp2annularstrokes + 
              this.data.linerdp1annularstrokes + 
              this.data.linerhwdpannularstrokes +
              this.data.linerdc2annularstrokes
            )
          ){
            this.condition = 11;
            let liner_length_across_dc2 = liner_dc2_annular_volume / this.data.linerdc2annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (liner_length_across_dc2 + this.data.openholedc2length + this.data.dc1length)
          }
        }

        // 2.3.1
        if(this.data.linerhwdplength > 0 &&
          this.data.linerdc2length >= 0 &&
          this.data.linerdc1length >= 0 &&
          this.data.linerhwdplength > 0 &&
          this.data.linerdp1length >= 0 &&
          this.data.linerdp2length >= 0

        ){
          let liner_hwdp_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes +
            this.data.openholehwdpannularstrokes + this.data.linerdc1annularstrokes + this.data.linerdc2annularstrokes);
          let liner_hwdp_annular_volume =  liner_hwdp_annular_strokes * this.data.pumpoutput;

          //2.3.2
          if((this.data.totalriserannularstrokes + 
            this.data.totalcsg1annularstrokes + 
            this.data.totalcsg2annularstrokes + 
            this.data.totalcsg3annularstrokes + 
            this.data.linerdp2annularstrokes + 
            this.data.linerdp1annularstrokes) <= 
            remaining_strokes && 
            remaining_strokes <=
            (
              this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes + 
              this.data.totalcsg2annularstrokes + 
              this.data.totalcsg3annularstrokes + 
              this.data.linerdp2annularstrokes + 
              this.data.linerdp1annularstrokes + 
              this.data.linerhwdpannularstrokes 
            )
          ){
            this.condition = 12;
            let liner_length_across_hwdp = liner_hwdp_annular_volume / this.data.linerhwdpannularcapacity
            this.pill_depth_annular = this.data.dbtm - (liner_length_across_hwdp + this.data.openholehwdplength + this.data.dc2length + 
              this.data.dc1length);

          }

        }

        // 2.4.1
        //  Liner DP1 Length >0, &&
        if(this.data.linerdp1length > 0 && 
          this.data.linerdc2length >= 0 &&
          this.data.linerdc1length >= 0 &&
          this.data.linerhwdplength >= 0 &&
          this.data.linerdp1length >= 0 &&
          this.data.linerdp2length >= 0
        ){

          let liner_dp1_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes
              + this.data.openholehwdpannularstrokes + this.data.openholedp1annularstrokes + this.data.linerdc1annularstrokes + 
              this.data.linerdc2annularstrokes + this.data.linerhwdpannularstrokes
          );
          let liner_dp1_annular_volume = liner_dp1_annular_strokes * this.data.pumpoutput

          // 2.4.2
          if((this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes + 
              this.data.totalcsg2annularstrokes + 
              this.data.totalcsg3annularstrokes + 
              this.data.linerdp2annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes +
                this.data.totalcsg2annularstrokes +
                this.data.totalcsg3annularstrokes +
                this.data.linerdp2annularstrokes +
                this.data.linerdp1annularstrokes
              )
          ){
            this.condition = 13;
            let liner_length_across_dp1 = liner_dp1_annular_volume / this.data.linerdp1annularcapacity
            this.pill_depth_annular = this.data.dbtm - (liner_length_across_dp1 + this.data.openholedp1length + this.data.hwdplength
              + this.data.dc2length + this.data.dc1length
            )
          }
        }


        // 2.5.1
        // Liner DP2 Length >0, &&
        // Liner DC2 Length > 0, Liner DC1 Length > 0 liner HWDP Length > 0 & LIner DP1 Length >0, Liner DP2 Length >0, 
        if(this.data.linerdp2length > 0 &&
          this.data.linerdc2length >= 0 && 
          this.data.linerdc1length >= 0 && 
          this.data.linerhwdplength >= 0 &&
          this.data.linerdp1length >= 0
        ){
          let liner_dp2_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes
            + this.data.openholehwdpannularstrokes + this.data.openholedp1annularstrokes + this.data.openholedp2annularstrokes + 
            this.data.linerdc1annularstrokes + this.data.linerdc2annularstrokes + this.data.linerhwdpannularstrokes + 
            this.data.linerdp1annularstrokes
          )

          let liner_dp2_annular_volume = liner_dp2_annular_strokes * this.data.pumpoutput;
          
          //2.5.2
          if((this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes + 
              this.data.totalcsg2annularstrokes + 
              this.data.totalcsg3annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes +
                this.data.totalcsg2annularstrokes +
                this.data.totalcsg3annularstrokes +
                this.data.linerdp2annularstrokes 
              )
          ){
            this.condition = 14;
            let liner_length_across_dp2 = liner_dp2_annular_volume / this.data.linerdp2annularcapacity
            this.pill_depth_annular = this.data.dbtm - (liner_length_across_dp2 + this.data.openholedp2length + this.data.dp1length +
              this.data.hwdplength + this.data.dc2length + this.data.dc1length
            );
          }
        }

      }


      // 3.0
      // CSG3 LENGTH > 0 &&
      if(this.data.csg3length > 0 && 
        (this.data.totalopenholeannularstrokes +
        this.data.totallinerannularstrokes
        )< current_strokes && 
        current_strokes <= 
        (
          this.data.totalopenholeannularstrokes +
          this.data.totallinerannularstrokes + 
          this.data.totalcsg3annularstrokes
        )
      ){

        // 3.1.1
        // CSG3 DC1 Length > 0, &&
        if(this.data.csg3dc1length > 0 && 
          this.data.csg3dc2length >= 0 &&
          this.data.csg3hwdplength >= 0 &&
          this.data.csg3dp1length >= 0 &&
          this.data.csg3dp2length >= 0
        ){
          let csg3_dc1_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.linerdc1annularstrokes);
          let csg3_dc1_annular_volume = csg3_dc1_annular_strokes * this.data.pumpoutput
          
          // 3.1.2
          if((this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes + 
              this.data.totalcsg2annularstrokes + 
              this.data.csg3dp2annularstrokes + 
              this.data.csg3dp1annularstrokes + 
              this.data.csg3hwdpannularstrokes + 
              this.data.csg3dc2annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes +
                this.data.totalcsg2annularstrokes +
                this.data.totalcsg3annularstrokes
              )
          ){
            this.condition = 15;
            let csg3_length_across_dc1 = csg3_dc1_annular_volume / this.data.csg3dc1annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg3_length_across_dc1 + this.data.linerdc1length + this.data.openholedc1length)
          }
        }


        // 3.2.1
        // CSG3 DC2 Length >0, &&
        if(this.data.csg3dc2length > 0 &&
          this.data.csg3dc1length >= 0 &&
          this.data.csg3hwdplength >= 0 &&
          this.data.csg3dp1length >= 0 &&
          this.data.csg3dp2length >= 0
        ){
          let csg3_dc2_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes + 
              this.data.linerdc1annularstrokes + this.data.linerdc2annularstrokes + this.data.csg3dc1annularstrokes);
          let csg3_dc2_annular_volume = csg3_dc2_annular_strokes * this.data.pumpoutput

          //3.2.2
          if((this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes + 
              this.data.totalcsg2annularstrokes + 
              this.data.csg3dp2annularstrokes + 
              this.data.csg3dp1annularstrokes + 
              this.data.csg3hwdpannularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes +
                this.data.totalcsg2annularstrokes +
                this.data.csg3dp2annularstrokes +
                this.data.csg3dp1annularstrokes +
                this.data.csg3hwdpannularstrokes +
                this.data.csg3dc2annularstrokes
              )
          ){
            this.condition = 16;
            let csg3_length_across_dc2 = csg3_dc2_annular_volume / this.data.csg3dc2annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg3_length_across_dc2 + this.data.openholedc2length + this.data.linerdc2length + this.data.dc1length)
          }

        }

        //3.3.1
        //CSG3 HWDP Length >0, &&
        if(this.data.csg3hwdplength > 0 &&
          this.data.csg3dc2length >= 0 &&
          this.data.csg3dc1length >= 0 &&
          this.data.csg3dp1length >= 0 &&
          this.data.csg3dp2length >= 0
        ){
          let csg3_hwdp_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes + 
          this.data.openholehwdpannularstrokes + this.data.linerdc1annularstrokes + this.data.linerdc2annularstrokes + this.data.linerhwdpannularstrokes
          + this.data.csg3dc1annularstrokes + this.data.csg3dc2annularstrokes);
          let csg3_hwdp_annular_volume = csg3_hwdp_annular_strokes * this.data.pumpoutput

          //3.3.2
          if((this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes + 
              this.data.totalcsg2annularstrokes + 
              this.data.csg3dp2annularstrokes + 
              this.data.csg3dp1annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes +
                this.data.totalcsg2annularstrokes +
                this.data.csg3dp2annularstrokes +
                this.data.csg3dp1annularstrokes +
                this.data.csg3hwdpannularstrokes
              )
          ){
            this.condition = 17;
            let csg3_length_across_hwdp = csg3_hwdp_annular_volume / this.data.csg3hwdpannularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg3_length_across_hwdp +  this.data.linerhwdplength + this.data.openholehwdplength + 
                this.data.dc2length + this.data.dc1length)
          }

        }


        //3.4.1
        //CSG3 DP1 Length >0, &&
        if(
          this.data.csg3dp1length > 0 &&
          this.data.csg3dc2length >= 0 &&
          this.data.csg3dc1length >= 0 &&
          this.data.csg3hwdplength >= 0 &&
          this.data.csg3dp2length >= 0
        ){
          let csg3_dp1_annular_strokes = current_strokes - (this.data.totalopenholeannularstrokes + this.data.totallinerannularstrokes +
            this.data.csg3dc1annularstrokes + this.data.csg3dc2annularstrokes + this.data.csg3hwdpannularstrokes);
          let csg3_dp1_annular_volume = csg3_dp1_annular_strokes * this.data.pumpoutput

          //3.4.2
          if((this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes + 
              this.data.totalcsg2annularstrokes + 
              this.data.csg3dp2annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes +
                this.data.totalcsg2annularstrokes +
                this.data.csg3dp2annularstrokes +
                this.data.csg3dp1annularstrokes
              )
          ){
            this.condition = 18;
            let csg3_length_across_dp1 = csg3_dp1_annular_volume / this.data.csg3dp1annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg3_length_across_dp1 +  this.data.linerdp1length + this.data.openholedp1length + 
                this.data.hwdplength + this.data.dc2length + this.data.dc1length)
          }

        }


        //3.5.1
        //CSG3 DP2 Length >0, &&
        if(
          this.data.csg3dp2length > 0 &&
          this.data.csg3dc2length >= 0 &&
          this.data.csg3dc1length >= 0 &&
          this.data.csg3hwdplength >= 0 &&
          this.data.csg3dp1length >= 0
        ){
          let csg3_dp2_annular_strokes = current_strokes - (this.data.totalopenholeannularstrokes + this.data.totallinerannularstrokes +
            this.data.csg3dc1annularstrokes + this.data.csg3dc2annularstrokes + this.data.csg3hwdpannularstrokes + 
            this.data.csg3dp1annularstrokes);
          let csg3_dp2_annular_volume = csg3_dp2_annular_strokes * this.data.pumpoutput

          //3.5.2
          if((this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes + 
              this.data.totalcsg2annularstrokes 
              // + 
              // this.data.totalcsg3annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes +
                this.data.totalcsg2annularstrokes +
                this.data.csg3dp2annularstrokes
              )
          ){
            this.condition = 19;
            let csg3_length_across_dp2 = csg3_dp2_annular_volume / this.data.csg3dp2annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg3_length_across_dp2 + this.data.linerdp2length + this.data.openholedp2length + 
            this.data.dp1length + this.data.hwdplength + this.data.dc2length + this.data.dc1length)
          }

        }

      }


      // 4.0
      // CSG2 LENGTH > 0 &&
      if(this.data.csg2length > 0 && 
        (this.data.totalopenholeannularstrokes +
        this.data.totallinerannularstrokes +
        this.data.totalcsg3annularstrokes
        )< current_strokes && 
        current_strokes <= 
        (
          this.data.totalopenholeannularstrokes +
          this.data.totallinerannularstrokes + 
          this.data.totalcsg3annularstrokes +
          this.data.totalcsg2annularstrokes 
        )
      ){

        // 4.1.1
        // CSG2 DC1 Length > 0, &&
        if(this.data.csg2dc1length > 0 && 
          this.data.csg2dc2length >= 0 &&
          this.data.csg2hwdplength >= 0 &&
          this.data.csg2dp1length >= 0 &&
          this.data.csg2dp2length >= 0
        ){
          let csg2_dc1_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.linerdc1annularstrokes
          + this.data.csg3dc1annularstrokes
          );
          let csg2_dc1_annular_volume = csg2_dc1_annular_strokes * this.data.pumpoutput
          
          // 4.1.2
          if((this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes +
              this.data.csg2dp2annularstrokes + 
              this.data.csg2dp1annularstrokes + 
              this.data.csg2hwdpannularstrokes + 
              this.data.csg2dc2annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes +
                this.data.totalcsg2annularstrokes
              )
          ){
            this.condition = 20;
            let csg2_length_across_dc1 = csg2_dc1_annular_volume / this.data.csg2dc1annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg2_length_across_dc1 + this.data.csg3dc1length + this.data.linerdc1length + 
              this.data.openholedc1length)
          }
        }


        // 4.2.1
        // CSG2 DC2 Length >0, &&
        if(this.data.csg2dc2length > 0 &&
          this.data.csg2dc1length >= 0 &&
          this.data.csg2hwdplength >= 0 &&
          this.data.csg2dp1length >= 0 &&
          this.data.csg2dp2length >= 0
        ){
          let csg2_dc2_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes + 
              this.data.linerdc1annularstrokes + this.data.linerdc2annularstrokes + this.data.csg3dc1annularstrokes + 
              this.data.csg3dc2annularstrokes + this.data.csg2dc1annularstrokes);
          let csg2_dc2_annular_volume = csg2_dc2_annular_strokes * this.data.pumpoutput

          //4.2.2
          if((this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes +
              this.data.csg2dp2annularstrokes + 
              this.data.csg2dp1annularstrokes + 
              this.data.csg2hwdpannularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes +
                this.data.csg2dp1annularstrokes +
                this.data.csg2dp2annularstrokes +
                this.data.csg2hwdpannularstrokes +
                this.data.csg2dc2annularstrokes
              )
          ){
            this.condition = 21;
            let csg2_length_across_dc2 = csg2_dc2_annular_volume / this.data.csg2dc2annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg2_length_across_dc2 + this.data.csg3dc2length + this.data.openholedc2length + this.data.linerdc2length + this.data.dc1length)
          }

        }

        //4.3.1
        //CSG2 HWDP Length >0, &&
        if(this.data.csg2hwdplength > 0 &&
          this.data.csg2dc2length >= 0 &&
          this.data.csg2dc1length >= 0 &&
          this.data.csg2dp1length >= 0 &&
          this.data.csg2dp2length >= 0
        ){
          let csg2_hwdp_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes + 
          this.data.openholehwdpannularstrokes + this.data.linerdc1annularstrokes + this.data.linerdc2annularstrokes + this.data.linerhwdpannularstrokes
          + this.data.csg3dc1annularstrokes + this.data.csg3dc2annularstrokes + this.data.csg3hwdpannularstrokes + this.data.csg2dc1annularstrokes
          + this.data.csg2dc2annularstrokes);
          let csg2_hwdp_annular_volume = csg2_hwdp_annular_strokes * this.data.pumpoutput

          //4.3.2
          if((this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes + 
              this.data.csg2dp2annularstrokes + 
              this.data.csg2dp1annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes +
                this.data.csg2dp2annularstrokes +
                this.data.csg2dp1annularstrokes +
                this.data.csg2hwdpannularstrokes
              )
          ){
            this.condition = 22;
            let csg2_length_across_hwdp = csg2_hwdp_annular_volume / this.data.csg2hwdpannularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg2_length_across_hwdp + this.data.csg3hwdplength + this.data.linerhwdplength + 
            this.data.openholehwdplength + this.data.dc2length + this.data.dc1length)
          }

        }


        //4.4.1
        //CSG2 DP1 Length >0, &&
        if(
          this.data.csg2dp1length > 0 &&
          this.data.csg2dc2length >= 0 &&
          this.data.csg2dc1length >= 0 &&
          this.data.csg2hwdplength >= 0 &&
          this.data.csg2dp2length >= 0
        ){
          let csg2_dp1_annular_strokes = current_strokes - (this.data.totalopenholeannularstrokes + this.data.totallinerannularstrokes +
            this.data.totalcsg3annularstrokes + this.data.csg2dc1annularstrokes + this.data.csg2dc2annularstrokes + 
            this.data.csg2hwdpannularstrokes);
          let csg2_dp1_annular_volume = csg2_dp1_annular_strokes * this.data.pumpoutput

          //4.4.2
          if((this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes +
              this.data.csg2dp2annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes +
                this.data.csg2dp2annularstrokes +
                this.data.csg2dp1annularstrokes
              )
          ){
            this.condition = 23;
            let csg2_length_across_dp1 = csg2_dp1_annular_volume / this.data.csg2dp1annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg2_length_across_dp1 +  this.data.csg3dp1length + this.data.linerdp1length + 
            this.data.openholedp1length + this.data.hwdplength + this.data.dc2length + this.data.dc1length)
          }

        }


        //4.5.1
        //CSG2 DP2 Length >0, &&
        if(
          this.data.csg2dp2length > 0 &&
          this.data.csg2dc2length >= 0 &&
          this.data.csg2dc1length >= 0 &&
          this.data.csg2hwdplength >= 0 &&
          this.data.csg2dp1length >= 0
        ){
          let csg2_dp2_annular_strokes = current_strokes - (this.data.totalopenholeannularstrokes + this.data.totallinerannularstrokes +
            this.data.totalcsg3annularstrokes + this.data.csg2dc1annularstrokes + this.data.csg2dc2annularstrokes + 
            this.data.csg2hwdpannularstrokes + this.data.csg2dp1annularstrokes);
          let csg2_dp2_annular_volume = csg2_dp2_annular_strokes * this.data.pumpoutput

          //4.5.2
          if((this.data.totalriserannularstrokes + 
              this.data.totalcsg1annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes +
                this.data.csg2dp2annularstrokes
              )
          ){
            this.condition = 24;
            let csg2_length_across_dp2 = csg2_dp2_annular_volume / this.data.csg2dp2annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg2_length_across_dp2 + this.data.csg3dp2length + this.data.linerdp2length + 
            this.data.openholedp2length + this.data.dp1length + this.data.hwdplength + this.data.dc2length + this.data.dc1length)
          }

        }

      }


      // 5.0
      // CSG1 LENGTH > 0 &&
      if(this.data.csg1length > 0 && 
        (this.data.totalopenholeannularstrokes +
        this.data.totallinerannularstrokes +
        this.data.totalcsg3annularstrokes +
        this.data.totalcsg2annularstrokes
        )< current_strokes && 
        current_strokes <= 
        (
          this.data.totalopenholeannularstrokes +
          this.data.totallinerannularstrokes + 
          this.data.totalcsg3annularstrokes +
          this.data.totalcsg2annularstrokes +
          this.data.totalcsg1annularstrokes  
        )
      ){

        // 5.1.1
        // CSG1 DC1 Length > 0, &&
        if(this.data.csg1dc1length > 0 && 
          this.data.csg1dc2length >= 0 &&
          this.data.csg1hwdplength >= 0 &&
          this.data.csg1dp1length >= 0 &&
          this.data.csg1dp2length >= 0
        ){
          let csg1_dc1_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.linerdc1annularstrokes
          + this.data.csg3dc1annularstrokes + this.data.csg2dc1annularstrokes
          );
          let csg1_dc1_annular_volume = csg1_dc1_annular_strokes * this.data.pumpoutput
          
          // 5.1.2
          if((this.data.totalriserannularstrokes +
              this.data.csg1dp2annularstrokes + 
              this.data.csg1dp1annularstrokes + 
              this.data.csg1hwdpannularstrokes + 
              this.data.csg1dc2annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.totalcsg1annularstrokes
              )
          ){
            this.condition = 25;
            let csg1_length_across_dc1 = csg1_dc1_annular_volume / this.data.csg1dc1annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg1_length_across_dc1 + this.data.csg2dc1length + this.data.csg3dc1length + 
             this.data.linerdc1length + this.data.openholedc1length)
          }
        }


        // 5.2.1
        // CSG1 DC2 Length >0, &&
        if(this.data.csg1dc2length > 0 &&
          this.data.csg1dc1length >= 0 &&
          this.data.csg1hwdplength >= 0 &&
          this.data.csg1dp1length >= 0 &&
          this.data.csg1dp2length >= 0
        ){
          let csg1_dc2_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes + 
              this.data.linerdc1annularstrokes + this.data.linerdc2annularstrokes + this.data.csg3dc1annularstrokes + 
              this.data.csg3dc2annularstrokes + this.data.csg2dc1annularstrokes + this.data.csg2dc2annularstrokes + this.data.csg1dc1annularstrokes);
          let csg1_dc2_annular_volume = csg1_dc2_annular_strokes * this.data.pumpoutput

          //5.2.2
          if((this.data.totalriserannularstrokes +
              this.data.csg1dp2annularstrokes + 
              this.data.csg1dp1annularstrokes + 
              this.data.csg1hwdpannularstrokes + 
              this.data.csg1dc1annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes +
                this.data.csg1dp1annularstrokes +
                this.data.csg1dp2annularstrokes +
                this.data.csg1hwdpannularstrokes +
                this.data.csg1dc2annularstrokes
              )
          ){
            this.condition = 26;
            let csg1_length_across_dc2 = csg1_dc2_annular_volume / this.data.csg1dc2annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg1_length_across_dc2 + this.data.csg2dc2length + this.data.csg3dc2length + 
            this.data.openholedc2length + this.data.linerdc2length + this.data.dc1length)
          }

        }

        //5.3.1
        //CSG1 HWDP Length >0, &&
        // CSG1 DC2 Length > 0, CSG1 DC1 Length > 0 CSG1 HWDP Length > 0 & CSG1 DP1 Length > 0, CSG2 DP2 Length >0 
        if(this.data.csg1hwdplength > 0 &&
          this.data.csg1dc2length >= 0 &&
          this.data.csg1dc1length >= 0 &&
          this.data.csg1dp1length >= 0 &&
          this.data.csg2dp2length >= 0
        ){
          let csg1_hwdp_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes + 
          this.data.openholehwdpannularstrokes + this.data.linerdc1annularstrokes + this.data.linerdc2annularstrokes + this.data.linerhwdpannularstrokes
          + this.data.csg3dc1annularstrokes + this.data.csg3dc2annularstrokes + this.data.csg3hwdpannularstrokes + this.data.csg1dc1annularstrokes 
          + this.data.csg1dc2annularstrokes + this.data.csg2hwdpannularstrokes + this.data.csg2dc1annularstrokes + this.data.csg2dc2annularstrokes);
          let csg1_hwdp_annular_volume = csg1_hwdp_annular_strokes * this.data.pumpoutput

          //5.3.2
          if((this.data.totalriserannularstrokes + 
              this.data.csg1dp2annularstrokes + 
              this.data.csg1dp1annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes +
                this.data.csg1dp2annularstrokes +
                this.data.csg1dp1annularstrokes +
                this.data.csg1hwdpannularstrokes
              )
          ){
            this.condition = 27;
            let csg1_length_across_hwdp = csg1_hwdp_annular_volume / this.data.csg1hwdpannularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg1_length_across_hwdp + this.data.csg2hwdplength + this.data.csg3hwdplength + 
                this.data.linerhwdplength + this.data.openholehwdplength + this.data.dc2length + this.data.dc1length)
          }

        }


        //5.4.1
        //CSG1 DP1 Length >0, &&
        if(
          this.data.csg1dp1length > 0 &&
          this.data.csg1dc2length >= 0 &&
          this.data.csg1dc1length >= 0 &&
          this.data.csg1hwdplength >= 0 &&
          this.data.csg1dp2length >= 0
        ){
          let csg1_dp1_annular_strokes = current_strokes - (this.data.totalopenholeannularstrokes + this.data.totallinerannularstrokes +
            this.data.totalcsg3annularstrokes + this.data.totalcsg2annularstrokes + this.data.csg1dc1annularstrokes + 
            this.data.csg1dc2annularstrokes + this.data.csg1hwdpannularstrokes);
          let csg1_dp1_annular_volume = csg1_dp1_annular_strokes * this.data.pumpoutput

          //5.4.2
          if((this.data.totalriserannularstrokes +
              this.data.csg1dp2annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes +
                this.data.csg1dp2annularstrokes +
                this.data.csg1dp1annularstrokes
              )
          ){
            this.condition = 28;
            let csg1_length_across_dp1 = csg1_dp1_annular_volume / this.data.csg1dp1annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg1_length_across_dp1 + this.data.csg2dp1length + this.data.csg3dp1length + 
              this.data.linerdp1length + this.data.openholedp1length + this.data.hwdplength + this.data.dc2length + this.data.dc1length)
          }

        }


        //5.5.1
        //CSG1 DP2 Length >0, &&
        if(
          this.data.csg1dp2length > 0 &&
          this.data.csg1dc2length >= 0 &&
          this.data.csg1dc1length >= 0 &&
          this.data.csg1hwdplength >= 0 &&
          this.data.csg1dp1length >= 0
        ){
          let csg1_dp2_annular_strokes = current_strokes - (this.data.totalopenholeannularstrokes + this.data.totallinerannularstrokes +
            this.data.totalcsg3annularstrokes + this.data.totalcsg2annularstrokes + this.data.csg1dc1annularstrokes + 
            this.data.csg1dc2annularstrokes + this.data.csg1hwdpannularstrokes + this.data.csg1dp1annularstrokes);
          let csg1_dp2_annular_volume = csg1_dp2_annular_strokes * this.data.pumpoutput

          //5.5.2
          if((this.data.totalriserannularstrokes
              ) <
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes + 
                this.data.csg1dp2annularstrokes
              )
          ){
            this.condition = 29;
            let csg1_length_across_dp2 = csg1_dp2_annular_volume / this.data.csg1dp2annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (csg1_length_across_dp2 + this.data.csg2dp2length + this.data.csg3dp2length + 
              this.data.linerdp2length + this.data.openholedp2length + this.data.dp1length + this.data.hwdplength + 
              this.data.dc2length + this.data.dc1length)
          }

        }

      }


      // 6.0
      // RISER LENGTH > 0 &&
      if(this.data.riserlength > 0 && 
        (this.data.totalopenholeannularstrokes +
        this.data.totallinerannularstrokes +
        this.data.totalcsg3annularstrokes +
        this.data.totalcsg2annularstrokes + 
        this.data.totalcsg1annularstrokes  
        )< current_strokes && 
        current_strokes <= 
        (
          this.data.totalopenholeannularstrokes +
          this.data.totallinerannularstrokes + 
          this.data.totalcsg3annularstrokes +
          this.data.totalcsg2annularstrokes +
          this.data.totalcsg1annularstrokes +
          this.data.totalriserannularstrokes
        )
      ){

        // 6.1.1
        // RISER DC1 Length > 0, &&
        if(this.data.riserdc1length > 0 && 
          this.data.riserdc2length >= 0 &&
          this.data.riserhwdplength >= 0 &&
          this.data.riserdp1length >= 0 &&
          this.data.riserdp2length >= 0
        ){
          let riser_dc1_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.linerdc1annularstrokes
          + this.data.csg3dc1annularstrokes + this.data.csg2dc1annularstrokes + this.data.csg1dc1annularstrokes
          );
          let riser_dc1_annular_volume = riser_dc1_annular_strokes * this.data.boosterpumpoutput
          
          // 6.1.2
          if((
              this.data.riserdp2annularstrokes + 
              this.data.riserdp1annularstrokes + 
              this.data.riserhwdpannularstrokes + 
              this.data.riserdc2annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.totalriserannularstrokes 
              )
          ){
            this.condition = 30;
            let riser_length_across_dc1 = riser_dc1_annular_volume / this.data.riserdc1annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (riser_length_across_dc1 + this.data.csg1dc1length + this.data.csg2dc1length + 
              this.data.csg3dc1length + this.data.linerdc1length + this.data.openholedc1length)
          }
        }


        // 6.2.1
        // RISER DC2 Length >0, &&
        if(this.data.riserdc2length > 0 &&
          this.data.riserdc1length >= 0 &&
          this.data.riserhwdplength >= 0 &&
          this.data.riserdp1length >= 0 &&
          this.data.riserdp2length >= 0
        ){
          let riser_dc2_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes + 
              this.data.linerdc1annularstrokes + this.data.linerdc2annularstrokes + this.data.csg3dc1annularstrokes + 
              this.data.csg3dc2annularstrokes + this.data.csg2dc1annularstrokes + this.data.csg2dc2annularstrokes + this.data.csg1dc1annularstrokes
              + this.data.csg1dc2annularstrokes + this.data.riserdc1strokes);
          let riser_dc2_annular_volume = riser_dc2_annular_strokes * this.data.boosterpumpoutput

          //6.2.2
          if((
              this.data.riserdp2annularstrokes + 
              this.data.riserdp1annularstrokes + 
              this.data.riserhwdpannularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.riserdp2annularstrokes +
                this.data.riserdp1annularstrokes +
                this.data.riserhwdpannularstrokes +
                this.data.riserdc2annularstrokes
              )
          ){
            this.condition = 31;
            let riser_length_across_dc2 = riser_dc2_annular_volume / this.data.riserdc2annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (riser_length_across_dc2 + this.data.csg1dc1length + this.data.csg2dc2length + 
              this.data.csg3dc2length + this.data.openholedc2length + this.data.linerdc2length + this.data.dc1length)
          }

        }

        //6.3.1
        //RISER HWDP Length >0, &&
        if(this.data.riserhwdplength > 0 &&
          this.data.riserdc2length >= 0 &&
          this.data.riserdc1length >= 0 &&
          this.data.riserdp1length >= 0 &&
          this.data.riserdp2length >= 0
        ){
          let riser_hwdp_annular_strokes = current_strokes - (this.data.openholedc1annularstrokes + this.data.openholedc2annularstrokes + 
          this.data.openholehwdpannularstrokes + this.data.linerdc1annularstrokes + this.data.linerdc2annularstrokes + this.data.linerhwdpannularstrokes
           + this.data.csg3dc1annularstrokes + this.data.csg3dc2annularstrokes + this.data.csg3hwdpannularstrokes + this.data.csg2dc1annularstrokes 
           + this.data.csg2dc2annularstrokes + this.data.csg2hwdpannularstrokes + this.data.csg1dc1annularstrokes + this.data.csg1dc2annularstrokes 
          + this.data.csg1hwdpannularstrokes + this.data.riserdc1annularstrokes + this.data.riserdc2annularstrokes);
          let riser_hwdp_annular_volume = riser_hwdp_annular_strokes * this.data.boosterpumpoutput

          //6.3.2
          if((
              this.data.csg1dp2annularstrokes + 
              this.data.csg1dp1annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.csg1dp2annularstrokes +
                this.data.csg1dp1annularstrokes +
                this.data.csg1hwdpannularstrokes
              )
          ){
            this.condition = 32;
            let riser_length_across_hwdp = riser_hwdp_annular_volume / this.data.riserhwdpannularcapacity;
            this.pill_depth_annular = this.data.dbtm - (riser_length_across_hwdp + this.data.csg1hwdplength + this.data.csg2hwdplength 
              + this.data.csg3hwdplength + this.data.linerhwdplength + this.data.openholehwdplength + this.data.dc2length + 
              this.data.dc1length)
          }

        }


        //6.4.1
        //RISER DP1 Length >0, &&
        if(
          this.data.riserdp1length > 0 &&
          this.data.riserdc2length >= 0 &&
          this.data.riserdc1length >= 0 &&
          this.data.riserhwdplength >= 0 &&
          this.data.riserdp2length >= 0
        ){
          let riser_dp1_annular_strokes = current_strokes - (this.data.totalopenholeannularstrokes + this.data.totallinerannularstrokes +
            this.data.totalcsg3annularstrokes + this.data.totalcsg2annularstrokes + this.data.totalcsg1annularstrokes + 
            this.data.riserdc1annularstrokes + this.data.riserdc2annularstrokes + this.data.riserhwdpannularstrokes);
          let riser_dp1_annular_volume = riser_dp1_annular_strokes * this.data.boosterpumpoutput

          //6.4.2
          if((
              this.data.riserdp2annularstrokes
              ) <= 
              remaining_strokes && 
              remaining_strokes <= 
              (
                this.data.riserdp2annularstrokes +
                this.data.riserdp1annularstrokes
              )
          ){
            this.condition = 33;
            let riser_length_across_dp1 = riser_dp1_annular_volume / this.data.riserdp1annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (riser_length_across_dp1 + this.data.csg1dp1length + this.data.csg2dp1length + 
              this.data.csg3dp1length + this.data.linerdp1length + this.data.openholedp1length + this.data.hwdplength + this.data.dc2length + 
              this.data.dc1length)
          }

        }


        //6.5.1
        //RISER DP2 Length >0, &&
        if(
          this.data.riserdp2length > 0 &&
          this.data.riserdc2length >= 0 &&
          this.data.riserdc1length >= 0 &&
          this.data.riserhwdplength >= 0 &&
          this.data.riserdp1length >= 0
        ){
          let riser_dp2_annular_strokes = current_strokes - (this.data.totalopenholeannularstrokes + this.data.totallinerannularstrokes +
            this.data.totalcsg3annularstrokes + this.data.totalcsg2annularstrokes + this.data.totalcsg1annularstrokes + 
            this.data.riserdc1annularstrokes + this.data.riserdc2annularstrokes + this.data.riserhwdpannularstrokes + 
            this.data.riserdp1annularstrokes);
          let riser_dp2_annular_volume = riser_dp2_annular_strokes * this.data.boosterpumpoutput

          //6.5.2
          if(
            this.data.riserdp2annularstrokes >= remaining_strokes 
          ){
            this.condition = 34;
            let riser_length_across_dp2 = riser_dp2_annular_volume / this.data.riserdp2annularcapacity;
            this.pill_depth_annular = this.data.dbtm - (riser_length_across_dp2 + this.data.csg1dp2length + this.data.csg2dp2length + 
              this.data.csg3dp2length + this.data.linerdp2length + this.data.openholedp2length + this.data.dp1length + 
              this.data.hwdplength + this.data.dc2length + this.data.dc1length)
          }

        }

      }
      return this.condition;
    },
    buildHydraulicsCalculator() {
      try {
        this.condition = 0;
        this.isNoData = false;
        if (this.data.tot_stk != null) {

        }else{
          this.isNoData = true;
        }
        // if (this.data.tot_stk != null) {
          svg = null;

          let ctx = null;
          let canvas = document.getElementById("canvas_id_hydrolics_cal");
          if (canvas) {
            canvas.width = this.widgetWidth - 100;
            canvas.height = this.widgetHeight - 50;
            ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);  // CLEARING RECT
          } else {
            d3.select(`#hydraulics-calculator${this.widgetId}`)
              .append("canvas")
              .attr("id", "canvas_id_hydrolics_cal")
              .attr("width", this.widgetWidth - 100)
              .attr("height", this.widgetHeight - 50)
              .attr("class", "mx-auto")
              .style("overflow", "visible");
            canvas = document.getElementById("canvas_id_hydrolics_cal");
            ctx = canvas.getContext("2d");
            canvas.addEventListener("mouseover", (event) => {
              this.mouseEnter(event, true);
            });
            // Function to handle mousemove event and display tooltips
            canvas.addEventListener("mousemove", (event) => {
              this.mouseMove(event, true);
            });
            // Function to handle mouseout event and clear the tooltip
            canvas.addEventListener("mouseout", (event) => {
              this.mouseLeave(event);
            });
          }
          let scale_dbtm =
            this.data.dbtm && this.data.dbtm >= 500 ? this.data.dbtm : 500;
          var y = d3
            .scaleLinear()
            .domain([scale_dbtm, 0]) // This is what is written on the Axis: from 0 to 100
            .range([this.widgetHeight - 60, 0]); // Note it is reversed

          this.yScale = y;

          let scaleX = d3
            .scaleLinear()
            .domain([0, 30])
            .range([0, this.widgetWidth - 100]);

          this.xScale = scaleX;

          if (Number(this.data.tot_stk) >= Number(this.data_initial.tot_stk)) {
            this.current_strokes +=
              Number(this.data.tot_stk) - Number(this.data_initial.tot_stk);
          } else {
            this.current_strokes += Number(this.data.tot_stk) || 1;
          }
          this.data_initial.tot_stk = Number(this.data.tot_stk);

          // console.log("current strokes : ", this.current_strokes);

          let current_strokes = this.current_strokes;
          let current_strokes_annulus = 0;
          //(CUMMULATIVETOTAL STROKES - INITIAL STROKES).
          if (Number(current_strokes) < Number(this.data_initial.tot_stk))
            current_strokes_annulus =
              Number(current_strokes) -
              (this.isLagDepth ? 0 : Number(this.data.stringstrokes));
          else
            current_strokes_annulus =
              Number(current_strokes) -
              (Number(0) +
                (this.isLagDepth ? 0 : Number(this.data.stringstrokes)));

          let remaining_strokes =
            Number(this.data.lagstrokes) - current_strokes_annulus;
          // this.pill_depth_annular = -1;
          this.getCondition(current_strokes, remaining_strokes);

          let current_volume = 0;
          let length_of_pill = 0;
          let pill_depth = 0;
          let pill_depth_annular = 0;
          let current_strokes_across_annulus = 0;
          let remaining_strokes_surface = 0;
          let annular_volume_across = 0;
          let length_of_pill_across = 0;
          let annular_volume_across_dp = 0;
          let annular_volume_across_hwdp = 0;

          if (this.condition == 1) {
            current_volume = current_strokes * this.data.pumpoutput;
            length_of_pill = current_volume / this.data.dp2stringcapacity;
            pill_depth = length_of_pill;
          } else if (this.condition == 2) {
            // current_volume = current_strokes * this.data.pumpoutput;
            current_volume = (current_strokes - this.data.dp2strokes) * this.data.pumpoutput;
            // let pumped_volume_inside_hwdp = current_volume - this.data.dpvolume;
            length_of_pill = current_volume / this.data.dp1stringcapacity;
            pill_depth = this.data.dp2length + length_of_pill;
          } else if (this.condition == "3a") {
            current_volume = current_strokes * this.data.pumpoutput;
            let pumped_volume_inside_hwdp = current_volume - (this.data.dp1stringvolume + this.data.dp2stringvolume);
            // let pumped_volume_inside_dc1_mwd =
            //   current_volume - (this.data.dpvolume + this.data.hwdpvolume);
            length_of_pill =
              pumped_volume_inside_hwdp / this.data.hwdpstringcapacity;
            pill_depth =
              this.data.dp1length + this.data.dp2length + length_of_pill;
          } else if (this.condition == "3b") {
            current_volume = current_strokes * this.data.pumpoutput;
            let pumped_volume_inside_dc2 =
              current_volume - (this.data.dp1stringvolume + this.data.dp2stringvolume + this.data.hwdpstringvolume);
            length_of_pill = pumped_volume_inside_dc2 / this.data.dc2stringcapacity;
            pill_depth =
              this.data.dp1length + this.data.dp2length + this.data.hwdplength + length_of_pill;
          } else if (this.condition == "3c") {
            current_volume = current_strokes * this.data.pumpoutput;
            let pumped_volume_inside_dc1 =
              current_volume -
              (this.data.dp1stringvolume + this.data.dp2stringvolume + this.data.hwdpstringvolume + this.data.dc2stringvolume);
            length_of_pill = pumped_volume_inside_dc1 / this.data.dc1stringcapacity;
            pill_depth =
              parseFloat(this.data.dp1length) +
              parseFloat(this.data.dp2length) +
              parseFloat(this.data.hwdplength) +
              parseFloat(this.data.dc2length) +
              length_of_pill;
          } 
          // else if (this.condition == "4a") {
          //   annular_volume_across =
          //     current_strokes_annulus * parseFloat(this.data.pumpoutput);
          //   length_of_pill_across =
          //     annular_volume_across / parseFloat(this.data.dc1annularcapacity);
          //   pill_depth_annular =
          //     parseFloat(this.data.dbtm) - length_of_pill_across;
          // } else if (this.condition == "4b") {
          //   annular_volume_across =
          //     current_strokes_annulus * parseFloat(this.data.pumpoutput);
          //   length_of_pill_across =
          //     annular_volume_across / parseFloat(this.data.dc2annularcapacity);
          //   pill_depth_annular =
          //     parseFloat(this.data.dbtm) - length_of_pill_across;
          // } else if (this.condition == "4c") {
          //   annular_volume_across =
          //     current_strokes_annulus * parseFloat(this.data.pumpoutput);
          //   length_of_pill_across =
          //     annular_volume_across / parseFloat(this.data.dc1annularcapacity);
          //   pill_depth_annular =
          //     parseFloat(this.data.dbtm) -
          //     length_of_pill_across -
          //     parseFloat(this.data.dc1length);
          // } else if (this.condition == 5) {
          //   annular_volume_across =
          //     parseFloat(current_strokes_annulus) *
          //     parseFloat(this.data.pumpoutput);
          //   annular_volume_across_hwdp =
          //     parseFloat(annular_volume_across) -
          //     parseFloat(this.data.dc1annularvolume);
          //   length_of_pill_across =
          //     parseFloat(annular_volume_across_hwdp) /
          //     parseFloat(this.data.hwdpannularcapacity);
          //   pill_depth_annular =
          //     parseFloat(this.data.dbtm) -
          //     (parseFloat(length_of_pill_across) +
          //       parseFloat(this.data.dc1length) +
          //       parseFloat(this.data.dc2length));
          // } else if (this.condition == 6) {
          //   annular_volume_across =
          //     parseFloat(current_strokes_annulus) *
          //     parseFloat(this.data.pumpoutput);
          //   annular_volume_across_dp =
          //     parseFloat(annular_volume_across) -
          //     (parseFloat(this.data.hwdpannularvolume) +
          //       parseFloat(this.data.dc1annularvolume) +
          //       parseFloat(this.data.dc2annularvolume));
          //   length_of_pill_across =
          //     parseFloat(annular_volume_across_dp) /
          //     parseFloat(this.data.dpannularcapacity);
          //   pill_depth_annular =
          //     parseFloat(this.data.dbtm) -
          //     (parseFloat(length_of_pill_across) +
          //       parseFloat(this.data.hwdplength) +
          //       parseFloat(this.data.dc1length) +
          //       parseFloat(this.data.dc2length));
          // }
          pill_depth =
            pill_depth > this.data.dbtm ? this.data.dbtm : pill_depth;
          // this.pill_depth_annular = pill_depth_annular;

          this.pill_depth = pill_depth;
          let bha_length =
            parseFloat(this.data.dbtm) -
            parseFloat(this.data.dc1length || 0) -
            parseFloat(this.data.dc2length || 0) -
            parseFloat(this.data.hwdplength || 0);
          this.data.bhalength;
          let first_marker =
            (parseFloat(this.data.dbtm) || 500) -
            parseFloat(this.data.dc2length || 0) -
            parseFloat(this.data.dc1length || 0);
          // console.log(
          //   " bha length, first_marker : ",
          //   bha_length,
          //   first_marker,
          //   this.data.dbtm,
          //   bha_length > 0 && first_marker >= 0,
          //   this.data.dbtm > 0
          // );

          // first_marker = 15000
          // bha_length = 10000
          if (true) {
            let scaleY = y;
            let dbtm =
              this.data.dbtm && this.data.dbtm >= 500 ? this.data.dbtm : 500;

            let poly = [
              { x: 7, y: dbtm }, //left bottom point
              { x: 7, y: dbtm * (1 - 0.0884) }, //left most top point
              {
                x: 10,
                y: dbtm * (1 - 0.106),
              }, //point merging with pipe again
              { x: 11, y: dbtm * (1 - 0.1415) },
              { x: 19, y: dbtm * (1 - 0.1415) },
              {
                x: 20,
                y: dbtm * (1 - 0.106),
              }, //point merging with pipe again
              { x: 23, y: dbtm * (1 - 0.0884) }, //Right most top point
              { x: 23, y: dbtm }, //Right most corner

              { x: 20, y: dbtm * (1 - 0.0084) },
              { x: 15, y: dbtm },
              { x: 10, y: dbtm * (1 - 0.0084) },
              // { x: 7, y: dbtm * (1 - 0.0084) + 80 },
            ];

            let poly1 = [
              { x: 11, y: dbtm * (1 - 0.1415) },
              { x: 10, y: 0 },
              { x: 20, y: 0 },
              { x: 19, y: dbtm * (1 - 0.1415) },
            ];

            ctx.beginPath();
            // ctx.moveTo(11, this.data.dbtm * (1 - 0.1415));
            ctx.shadowBlur = 15;
            ctx.shadowColor = "gray";
            ctx.fillStyle = "gray"; // Grid line color
            // ctx.fillRect(37, 11, 25, 290);
            ctx.moveTo(scaleX(poly1[0].x), scaleY(poly1[0].y));
            poly1.forEach((p) => {
              ctx.lineTo(scaleX(p.x), scaleY(p.y));
              ctx.fillStyle = "gray";
              // ctx.stroke();
            });
            ctx.fill();
            ctx.closePath();
            // ctx.stroke();
            ctx.closePath();
            ctx.beginPath();
            ctx.shadowBlur = 5;
            ctx.shadowColor = "";
            ctx.moveTo(scaleX(poly[0].x), scaleY(poly[0].y));
            poly.forEach((p) => {
              // console.log("poly 1 : ", p.y, scaleY(p.y));
              ctx.lineTo(scaleX(p.x), scaleY(p.y));
              ctx.fillStyle = "#007EA7";
              // ctx.stroke();
            });
            ctx.fill();
            //Add pill tracer
            if (this.pillTraces) this.showPill(ctx);
            this.showCasing();
          } else {
            // this.$toast.error("Not a condition for hydraulcis", {
            //   position: "top",
            //   duration: 1000,
            // });
            this.isNoData = true;
            this.message = "Irrelevant data to hydraulics.";
          }
          // }
        // } else {
        //   this.$toast.error("Data not available", {
        //     position: "top",
        //     duration: 1000,
        //   });
        //   this.message = "Data not available";
        //   this.isNoData = true;
        // }
        this.getSlugCalulationData();
      } catch (error) {
        svg = d3
          .select(`#hydraulics-calculator${this.widgetId}`)
          .selectAll("*")
          .remove();
        console.error(error);
      }
    },
    // buildHydraulicsCalculator() {
    //   try {
    //     this.isNoData = false;
    //     if (this.data.tot_stk != null) {
    //       svg = null;

    //       let ctx = null;
    //       let canvas = document.getElementById("canvas_id_hydrolics_cal");
    //       if (canvas) {
    //         canvas.width = this.widgetWidth - 100;
    //         canvas.height = this.widgetHeight - 50;
    //         ctx = canvas.getContext("2d");
    //       } else {
    //         d3.select(`#hydraulics-calculator${this.widgetId}`)
    //           .append("canvas")
    //           .attr("id", "canvas_id_hydrolics_cal")
    //           .attr("width", this.widgetWidth - 100)
    //           .attr("height", this.widgetHeight - 50)
    //           .attr("class", "mx-auto")
    //           .style("overflow", "visible");
    //         canvas = document.getElementById("canvas_id_hydrolics_cal");
    //         ctx = canvas.getContext("2d");
    //         canvas.addEventListener("mouseover", (event) => {
    //           this.mouseEnter(event, true);
    //         });
    //         // Function to handle mousemove event and display tooltips
    //         canvas.addEventListener("mousemove", (event) => {
    //           this.mouseMove(event, true);
    //         });
    //         // Function to handle mouseout event and clear the tooltip
    //         canvas.addEventListener("mouseout", (event) => {
    //           this.mouseLeave(event);
    //         });
    //       }
    //       let scale_dbtm =
    //         this.data.dbtm && this.data.dbtm >= 500 ? this.data.dbtm : 500;
    //       var y = d3
    //         .scaleLinear()
    //         .domain([scale_dbtm, 0]) // This is what is written on the Axis: from 0 to 100
    //         .range([this.widgetHeight - 60, 0]); // Note it is reversed

    //       this.yScale = y;

    //       let scaleX = d3
    //         .scaleLinear()
    //         .domain([0, 30])
    //         .range([0, this.widgetWidth - 100]);

    //       this.xScale = scaleX;

    //       if (Number(this.data.tot_stk) >= Number(this.data_initial.tot_stk)) {
    //         this.current_strokes +=
    //           Number(this.data.tot_stk) - Number(this.data_initial.tot_stk);
    //       } else {
    //         this.current_strokes += Number(this.data.tot_stk) || 1;
    //       }
    //       this.data_initial.tot_stk = Number(this.data.tot_stk);

    //       console.log("current strokes : ", this.current_strokes);

    //       let current_strokes = this.current_strokes;
    //       let current_strokes_annulus = 0;
    //       //(CUMMULATIVETOTAL STROKES - INITIAL STROKES).
    //       if (Number(current_strokes) < Number(this.data_initial.tot_stk))
    //         current_strokes_annulus =
    //           Number(current_strokes) -
    //           (this.isLagDepth ? 0 : Number(this.data.stringstrokes));
    //       else
    //         current_strokes_annulus =
    //           Number(current_strokes) -
    //           (Number(0) +
    //             (this.isLagDepth ? 0 : Number(this.data.stringstrokes)));

    //       let remaining_strokes =
    //         Number(this.data.lagstrokes) - current_strokes_annulus;

    //       this.getCondition(current_strokes, remaining_strokes);

    //       let current_volume = 0;
    //       let length_of_pill = 0;
    //       let pill_depth = 0;
    //       let pill_depth_annular = 0;
    //       let current_strokes_across_annulus = 0;
    //       let remaining_strokes_surface = 0;
    //       let annular_volume_across = 0;
    //       let length_of_pill_across = 0;
    //       let annular_volume_across_dp = 0;
    //       let annular_volume_across_hwdp = 0;

    //       if (this.condition == 1) {
    //         current_volume = current_strokes * this.data.pumpoutput;
    //         length_of_pill = current_volume / this.data.dpcapacity;
    //         pill_depth = length_of_pill;
    //       } else if (this.condition == 2) {
    //         current_volume = current_strokes * this.data.pumpoutput;
    //         let pumped_volume_inside_hwdp = current_volume - this.data.dpvolume;
    //         length_of_pill = pumped_volume_inside_hwdp / this.data.hwdpcapacity; //getting NaN
    //         pill_depth = this.data.dplength + length_of_pill;
    //       } else if (this.condition == "3a") {
    //         current_volume = current_strokes * this.data.pumpoutput;
    //         let pumped_volume_inside_dc1_mwd =
    //           current_volume - (this.data.dpvolume + this.data.hwdpvolume);
    //         length_of_pill =
    //           pumped_volume_inside_dc1_mwd / this.data.dc1capacity; //getting NaN
    //         pill_depth =
    //           this.data.dplength + this.data.hwdplength + length_of_pill;
    //       } else if (this.condition == "3b") {
    //         current_volume = current_strokes * this.data.pumpoutput;
    //         let pumped_volume_inside_dc2 =
    //           current_volume - (this.data.dpvolume + this.data.hwdpvolume);
    //         length_of_pill = pumped_volume_inside_dc2 / this.data.dc2capacity; //getting NaN
    //         pill_depth =
    //           this.data.dplength + this.data.hwdplength + length_of_pill;
    //       } else if (this.condition == "3c") {
    //         current_volume = current_strokes * this.data.pumpoutput;
    //         let pumped_volume_inside_dc2 =
    //           current_volume -
    //           (this.data.dpvolume + this.data.hwdpvolume + this.data.dc2volume);
    //         length_of_pill = pumped_volume_inside_dc2 / this.data.dc1capacity; //getting NaN
    //         pill_depth =
    //           parseFloat(this.data.dplength) +
    //           parseFloat(this.data.hwdplength) +
    //           length_of_pill +
    //           parseFloat(this.data.dc1length);
    //       } else if (this.condition == "4a") {
    //         annular_volume_across =
    //           current_strokes_annulus * parseFloat(this.data.pumpoutput);
    //         length_of_pill_across =
    //           annular_volume_across / parseFloat(this.data.dc1annularcapacity);
    //         pill_depth_annular =
    //           parseFloat(this.data.dbtm) - length_of_pill_across;
    //       } else if (this.condition == "4b") {
    //         annular_volume_across =
    //           current_strokes_annulus * parseFloat(this.data.pumpoutput);
    //         length_of_pill_across =
    //           annular_volume_across / parseFloat(this.data.dc2annularcapacity);
    //         pill_depth_annular =
    //           parseFloat(this.data.dbtm) - length_of_pill_across;
    //       } else if (this.condition == "4c") {
    //         annular_volume_across =
    //           current_strokes_annulus * parseFloat(this.data.pumpoutput);
    //         length_of_pill_across =
    //           annular_volume_across / parseFloat(this.data.dc1annularcapacity);
    //         pill_depth_annular =
    //           parseFloat(this.data.dbtm) -
    //           length_of_pill_across -
    //           parseFloat(this.data.dc1length);
    //       } else if (this.condition == 5) {
    //         annular_volume_across =
    //           parseFloat(current_strokes_annulus) *
    //           parseFloat(this.data.pumpoutput);
    //         annular_volume_across_hwdp =
    //           parseFloat(annular_volume_across) -
    //           parseFloat(this.data.dc1annularvolume);
    //         length_of_pill_across =
    //           parseFloat(annular_volume_across_hwdp) /
    //           parseFloat(this.data.hwdpannularcapacity);
    //         pill_depth_annular =
    //           parseFloat(this.data.dbtm) -
    //           (parseFloat(length_of_pill_across) +
    //             parseFloat(this.data.dc1length) +
    //             parseFloat(this.data.dc2length));
    //       } else if (this.condition == 6) {
    //         annular_volume_across =
    //           parseFloat(current_strokes_annulus) *
    //           parseFloat(this.data.pumpoutput);
    //         annular_volume_across_dp =
    //           parseFloat(annular_volume_across) -
    //           (parseFloat(this.data.hwdpannularvolume) +
    //             parseFloat(this.data.dc1annularvolume) +
    //             parseFloat(this.data.dc2annularvolume));
    //         length_of_pill_across =
    //           parseFloat(annular_volume_across_dp) /
    //           parseFloat(this.data.dpannularcapacity);
    //         pill_depth_annular =
    //           parseFloat(this.data.dbtm) -
    //           (parseFloat(length_of_pill_across) +
    //             parseFloat(this.data.hwdplength) +
    //             parseFloat(this.data.dc1length) +
    //             parseFloat(this.data.dc2length));
    //       }
    //       pill_depth =
    //         pill_depth > this.data.dbtm ? this.data.dbtm : pill_depth;
    //       // this.pill_depth_annular = pill_depth_annular;

    //       this.pill_depth = pill_depth;
    //       let bha_length =
    //         parseFloat(this.data.dbtm) -
    //         parseFloat(this.data.dc1length || 0) -
    //         parseFloat(this.data.dc2length || 0) -
    //         parseFloat(this.data.hwdplength || 0);
    //       this.data.bhalength;
    //       let first_marker =
    //         (parseFloat(this.data.dbtm) || 500) -
    //         parseFloat(this.data.dc2length || 0) -
    //         parseFloat(this.data.dc1length || 0);
    //       console.log(
    //         " bha length, first_marker : ",
    //         bha_length,
    //         first_marker,
    //         this.data.dbtm,
    //         bha_length > 0 && first_marker >= 0,
    //         this.data.dbtm > 0
    //       );

    //       // first_marker = 15000
    //       // bha_length = 10000
    //       if (true) {
    //         let scaleY = y;
    //         let dbtm =
    //           this.data.dbtm && this.data.dbtm >= 500 ? this.data.dbtm : 500;

    //         let poly = [
    //           { x: 7, y: dbtm }, //left bottom point
    //           { x: 7, y: dbtm * (1 - 0.0884) }, //left most top point
    //           {
    //             x: 10,
    //             y: dbtm * (1 - 0.106),
    //           }, //point merging with pipe again
    //           { x: 11, y: dbtm * (1 - 0.1415) },
    //           { x: 19, y: dbtm * (1 - 0.1415) },
    //           {
    //             x: 20,
    //             y: dbtm * (1 - 0.106),
    //           }, //point merging with pipe again
    //           { x: 23, y: dbtm * (1 - 0.0884) }, //Right most top point
    //           { x: 23, y: dbtm }, //Right most corner

    //           { x: 20, y: dbtm * (1 - 0.0084) },
    //           { x: 15, y: dbtm },
    //           { x: 10, y: dbtm * (1 - 0.0084) },
    //           // { x: 7, y: dbtm * (1 - 0.0084) + 80 },
    //         ];

    //         let poly1 = [
    //           { x: 11, y: dbtm * (1 - 0.1415) },
    //           { x: 10, y: 0 },
    //           { x: 20, y: 0 },
    //           { x: 19, y: dbtm * (1 - 0.1415) },
    //         ];

    //         ctx.beginPath();
    //         // ctx.moveTo(11, this.data.dbtm * (1 - 0.1415));
    //         ctx.shadowBlur = 15;
    //         ctx.shadowColor = "gray";
    //         ctx.fillStyle = "gray"; // Grid line color
    //         // ctx.fillRect(37, 11, 25, 290);
    //         ctx.moveTo(scaleX(poly1[0].x), scaleY(poly1[0].y));
    //         poly1.forEach((p) => {
    //           ctx.lineTo(scaleX(p.x), scaleY(p.y));
    //           ctx.fillStyle = "gray";
    //           // ctx.stroke();
    //         });
    //         ctx.fill();
    //         ctx.closePath();
    //         // ctx.stroke();
    //         ctx.closePath();
    //         ctx.beginPath();
    //         ctx.shadowBlur = 5;
    //         ctx.shadowColor = "";
    //         ctx.moveTo(scaleX(poly[0].x), scaleY(poly[0].y));
    //         poly.forEach((p) => {
    //           console.log("poly 1 : ", p.y, scaleY(p.y));
    //           ctx.lineTo(scaleX(p.x), scaleY(p.y));
    //           ctx.fillStyle = "#007EA7";
    //           // ctx.stroke();
    //         });
    //         ctx.fill();
    //         //Add pill tracer
    //         if (this.pillTraces) this.showPill(ctx);
    //         this.showCasing();
    //       } else {
    //         // this.$toast.error("Not a condition for hydraulcis", {
    //         //   position: "top",
    //         //   duration: 1000,
    //         // });
    //         this.isNoData = true;
    //         this.message = "Irrelevant data to hydraulics.";
    //       }
    //       // }
    //     } else {
    //       this.$toast.error("Data not available", {
    //         position: "top",
    //         duration: 1000,
    //       });
    //       this.message = "Data not available";
    //       this.isNoData = true;
    //     }
    //     this.getSlugCalulationData();
    //   } catch (error) {
    //     svg = d3
    //       .select(`#hydraulics-calculator${this.widgetId}`)
    //       .selectAll("*")
    //       .remove();
    //     console.error(error);
    //   }
    // },
    async getWellGeometry() {
      try {
        let geometryResponse = await Service.WellServices.getWellGeometry({
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
        });
        for (let i = 0; i < geometryResponse.data.rows.length; i++) {
          // console.log("geometry : ", geometryResponse.data.rows[i]);
          if (
            geometryResponse.data.rows[i] &&
            geometryResponse.data.rows[i].section_type
              .toLowerCase()
              .includes("csg")
          ) {
            this.geometry = geometryResponse.data.rows[i];
           
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getWellInformation() {
      try {
        let dataForTable = await Service.WellServices.getWellInformation({});
        dataForTable.data.rows &&
          dataForTable.data.rows.map((info) => {
            if (info.latest_bha && info.sourceid == this.trajectoryLogId)
              this.planId = info.planid;
          });
      } catch (error) {
        console.error(`error in get well info : ${error}`);
      }
    },
    mouseOver(event, isPipe) {
      this.isPipeString = isPipe;
      this.mouse_x = event.clientX;
      this.mouse_y = event.clientY;
    },
    mouseEnter(event, isPipe) {
      this.tool_popup = true;
      this.isPipeString = isPipe;
      // this.$el.addEventListener("mousemove", this.mouseMove, false);
    },
    mouseLeave(event) {
      this.tool_popup = false;
      // this.$el.removeEventListener('mousemove', this.mouseMove());
    },
    mouseMove(event, isPipe) {
      this.isPipeString = isPipe;
      this.mouse_x = event.clientX;
      this.mouse_y = event.clientY;
    },
    async getMenmonics() {
      let valueAt = "end";

      let dconfig = {};
      let wells = this.$store.state.data.wells;
      await this.getMnemonicType(this.fields_required);
      // this.fields_required.map((mn) => this.getMnemonicType(mn));
      let well = wells[this.wellId];
      if (well) {
        await this.getWellGeometry();
        for (let i in this.mnemonic_groups) {
          let response = await Service.WellServices.getSingleValueTimeLog({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            field: this.mnemonic_groups[i].join(","),
            type: i,
            max: this.max,
            topic: this.topic,
          });
          // console.log("response of data : response.data", response.data);
          let tempResponse = {};
          for (let res in response.data[0]) {
            if (res == "time") tempResponse[res] = response.data[0][res];
            else tempResponse[res] = Number(response.data[0][res]);
          }
          // console.log("got the data : ", tempResponse, this.data);

          this.data = { ...this.data, ...tempResponse };
          this.data_initial = { ...this.data, ...response.data[0] };
        }
        // CONVERTING DATA CODE STARTS
        if (this.isWellMetrics) {
          await this.converting_data();
        }
        // CONVERTING DATA CODE ENDS
      } else {
      }
      return this.data;
    },
    async getMnemonicType(mnemonic) {
      // console.time("get mnemonic type", mnemonic)
      let mnemonic_groups = {};
      this.unitConversionFactors = {};
      // let all_mnemonics =
      //   this.$store.state.disp.displays[this.displayId].mnemonics;
      let logDetails = await helperServices.getIndexDetails(this.wellId);
      let all_mnemonics = logDetails.data.logs[this.logType].mnemonics;
      const source_mnemonice = logDetails.data.logs[this.logType].source;
      const tags = logDetails.data.logs[this.logType].tags;
      let mnemonic_type = "UNPREFERRED";
      if (Array.isArray(mnemonic)) {
        if (this.isWellMetrics) {
          for (let tag of mnemonic) {
            for (let i in all_mnemonics) {
              try {
                if (all_mnemonics[i][tag]) {
                  this.unitConversionFactors[tag] = all_mnemonics[i][tag];
                  let units = await helperServices.getUnitCategories(
                    this.unitConversionFactors[tag].category
                  );
                  if (units) {
                    this.unitConversionFactors[tag].units_category_id =
                      units.units_category_id;
                    db.unitfactors
                      .where("units_category_id")
                      .equals(units.units_category_id)
                      .each((fact) => {
                        if (
                          fact.from_unit ==
                            this.unitConversionFactors[tag].display_unit &&
                          fact.to_unit ==
                            this.unitConversionFactors[tag].pnrg_unit
                        ) {
                          this.unitConversionFactors[tag].to_unit =
                            fact.to_unit;
                          this.unitConversionFactors[tag].conversion_factor =
                            fact.conversion_factor;
                        }
                      });
                  }
                }
              } catch (err) {
                console.error(err);
              }
            }
            if (!this.unitConversionFactors.hasOwnProperty(tag)) {
              console.log("tag which is not existed", tag);
            }
          }
        }
        for (let tag of mnemonic) {
          const mnemonicKey = tags.findIndex((t) => t == tag);
          const actualKey = source_mnemonice[mnemonicKey];
          for (let i in all_mnemonics) {
            if (!actualKey) {
              mnemonic_type = "SOLUTION";
              break;
            }
            if (all_mnemonics[i][actualKey]) {
              mnemonic_type = i;
              mnemonic_groups[i] = [];
              break;
            }
          }
          if (!this.mnemonic_groups[mnemonic_type])
            this.mnemonic_groups[mnemonic_type] = [];
          if (this.mnemonic_groups[mnemonic_type].indexOf(tag) == -1)
            this.mnemonic_groups[mnemonic_type] = [
              ...this.mnemonic_groups[mnemonic_type],
              tag,
            ];
        }

        return mnemonic_type;
      } else {
        const mnemonicKey = tags.findIndex((t) => t == mnemonic);
        const actualKey = source_mnemonice[mnemonicKey];

        for (let i in all_mnemonics) {
          if (!actualKey) {
            mnemonic_type = "SOLUTION";
            break;
          }
          if (all_mnemonics[i][actualKey]) {
            mnemonic_type = i;
            mnemonic_groups[i] = [];
            break;
          }
        }
        if (!this.mnemonic_groups[mnemonic_type])
          this.mnemonic_groups[mnemonic_type] = [];
        if (this.mnemonic_groups[mnemonic_type].indexOf(mnemonic) == -1)
          this.mnemonic_groups[mnemonic_type] = [
            ...this.mnemonic_groups[mnemonic_type],
            mnemonic,
          ];
        return mnemonic_type;
      }
    },
    getSlugCalulationData() {
      try {
        //unit conversion calculations
        // console.log(" this.conversionFactors ", this.conversionFactors);
        if (
          this.conversionFactors?.dry_pipe_length_hyd?.conversion_factor &&
          this.conversionFactors?.dry_pipe_length_hyd?.conversion_factor != ""
        ) {
          this.c_dry_pipe_length =
            this.dry_pipe_length *
            this.conversionFactors.dry_pipe_length_hyd.conversion_factor;
        } else this.c_dry_pipe_length = this.dry_pipe_length;
        if (
          this.conversionFactors?.slug_weight_hyd?.conversion_factor &&
          this.conversionFactors?.slug_weight_hyd?.conversion_factor != ""
        ) {
          this.c_slug_weight =
            this.slug_weight *
            this.conversionFactors.slug_weight_hyd.conversion_factor;
        } else this.c_slug_weight = this.slug_weight;
        if (
          this.conversionFactors?.slug_dry_pipe_length_hyd?.conversion_factor &&
          this.conversionFactors?.slug_dry_pipe_length_hyd?.conversion_factor !=
            ""
        ) {
          this.c_slug_dry_pipe_length =
            this.slug_dry_pipe_length *
            this.conversionFactors.slug_dry_pipe_length_hyd.conversion_factor;
        } else this.c_slug_dry_pipe_length = this.slug_dry_pipe_length;
        if (
          this.conversionFactors?.slug_volume_hyd?.conversion_factor &&
          this.conversionFactors?.slug_volume_hyd?.conversion_factor != ""
        ) {
          this.c_slug_volume =
            this.slug_volume *
            this.conversionFactors.slug_volume_hyd.conversion_factor;
        } else this.c_slug_volume = this.slug_volume;
        if (
          this.conversionFactors?.annular_capacity_hyd?.conversion_factor &&
          this.conversionFactors?.annular_capacity_hyd?.conversion_factor != ""
        ) {
          // this.c_annular_capacity =
          //   (1 / this.annular_capacity) *
          //   this.conversionFactors.annular_capacity_hyd.conversion_factor;
          this.c_annular_capacity =
            (this.annular_capacity) *
            this.conversionFactors.annular_capacity_hyd.conversion_factor;
        } else{
          // this.c_annular_capacity = this.annular_capacity
          //   ? 1 / this.annular_capacity
          //   : this.annular_capacity;
          this.c_annular_capacity = this.annular_capacity;
        }
        if (
          this.conversionFactors?.annular_slug_volume_hyd?.conversion_factor &&
          this.conversionFactors?.annular_slug_volume_hyd?.conversion_factor !=
            ""
        ) {
          this.c_annular_slug_volume =
            this.annular_slug_volume *
            this.conversionFactors.annular_slug_volume_hyd.conversion_factor;
        } else this.c_annular_slug_volume = this.annular_slug_volume;
        if (
          this.conversionFactors?.height_spot_column_hyd?.conversion_factor &&
          this.conversionFactors?.height_spot_column_hyd?.conversion_factor !=
            ""
        ) {
          this.c_height_spot_column =
            this.height_spot_column *
            this.conversionFactors.height_spot_column_hyd.conversion_factor;
        } else this.c_height_spot_column = this.height_spot_column;
        if (
          this.conversionFactors?.drill_string_volume_left_hyd
            ?.conversion_factor &&
          this.conversionFactors?.drill_string_volume_left_hyd
            ?.conversion_factor != ""
        ) {
          this.c_drill_string_volume_left =
            this.drill_string_volume_left *
            this.conversionFactors.drill_string_volume_left_hyd
              .conversion_factor;
        } else this.c_drill_string_volume_left = this.drill_string_volume_left;

        // console.log(
        //   "calculation vvvvvv ",
        //   this.c_slug_volume,
        //   this.c_slug_dry_pipe_length
        // );
        // Barrels of slug required for a desired length of dry pipe
        if (this.c_dry_pipe_length && this.c_slug_weight) {
          let hydrostatic_pressure =
            parseFloat(this.data["mud density"]).toFixed(4) *
            0.052 *
            (parseFloat(this.c_dry_pipe_length).toFixed(4) || 184);
          this.hydrostatic_pressure = hydrostatic_pressure;
          // console.log(
          //   " see hydrostatic_pressure : ",
          //   hydrostatic_pressure,
          //   this.data["mud density"],
          //   this.mudDensity
          // );
          // hydrostatic_pressure = this.data['hydrostaticpressure'];.toFixed(4)
          let gradient_btn_slug_mud_wt =
            ((parseFloat(this.c_slug_weight).toFixed(4) || 0) -
              parseFloat(this.data["mud density"] || this.mudDensity).toFixed(
                4
              )) *
            0.052;
          this.gradient_btn_slug_mud_wt = gradient_btn_slug_mud_wt;
          let slug_in_dp = hydrostatic_pressure / gradient_btn_slug_mud_wt;
          this.slug_length = slug_in_dp;

          let slug_volume_temp =
            slug_in_dp * ((parseFloat(this.data["dp1stringcapacity"]) + parseFloat(this.data["dp2stringcapacity"]))/2).toFixed(4);
          this.calc_slugVolume = slug_volume_temp;
          // Weight of slug required for a desired length of dry pipe with a set volume

          if (
            parseFloat(this.c_annular_capacity).toFixed(4) &&
            parseFloat(this.c_annular_slug_volume).toFixed(4)
          ) {
            // Volume, height, and pressure gained because of slug:
            let volume_gained =
              (parseFloat(this.c_dry_pipe_length).toFixed(4) || 184) *
              ((parseFloat(this.data["dp1stringcapacity"]) + parseFloat(this.data["dp2stringcapacity"]))/2).toFixed(4);
            let height_gained =
              parseFloat(this.c_annular_capacity).toFixed(4) *
              parseFloat(this.c_annular_slug_volume).toFixed(4);
            let hydrostatic_pressure_gained =
              height_gained * gradient_btn_slug_mud_wt;
            this.heightGained = height_gained;
            this.pressureGained = hydrostatic_pressure_gained;
            this.volumeGained = volume_gained;
            // Barrels of spotting fluid (pill) required
            // Pump strokes required to spot the pill
          } else {
            this.heightGained = 0;
            this.pressureGained = 0;
            this.volumeGained = 0;
          }
        } else {
          this.slug_length = 0;
          this.slugVolume = 0;
          this.heightGained = 0;
          this.pressureGained = 0;
          this.volumeGained = 0;
        }

        if (this.c_slug_dry_pipe_length && this.c_slug_volume) {
          let slug_length =
            parseFloat(this.c_slug_volume) /
            ((parseFloat(this.data["dp1stringcapacity"]) + parseFloat(this.data["dp2stringcapacity"]))/2).toFixed(4);
          this.calc_slug_length = parseFloat(slug_length);

          let hydrostatic_pressure_for_drop =
            parseFloat(this.data["mud density"] || this.mudDensity).toFixed(4) *
            0.052 *
            (parseFloat(this.c_slug_dry_pipe_length).toFixed(4) || 184);
          this.calc_hydrostatic_pressure_for_drop =
            hydrostatic_pressure_for_drop;
          let slug_weight =
            hydrostatic_pressure_for_drop / (0.052 * slug_length) +
            parseFloat(this.data["mud density"] || this.mudDensity);
          // console.log(
          //   "~~~~~~~~ calculator mud weig ~~~~ ",
          //   hydrostatic_pressure_for_drop / (0.052 * slug_length) +
          //     parseFloat(this.data["mud density"] || this.mudDensity).toFixed(4)
          // );
          this.calc_slug_weight = slug_weight;
        }
        if (
          parseFloat(this.c_drill_string_volume_left) &&
          parseFloat(this.stroke_required_displace_surface) &&
          parseFloat(this.washout_factor)
        ) {
          let annulur_volume_required_dc1 =
            parseFloat(this.data["openholedc1annularcapacity"]).toFixed(4) *
            (1 + parseFloat(this.washout_factor) / 100) *
            (this.c_height_spot_column &&
            parseFloat(this.c_height_spot_column) >=
              Number(parseFloat(this.data["openholedc1length"]).toFixed(4))
              ? Number(parseFloat(this.data["openholedc1length"]).toFixed(4))
              : Number(parseFloat(this.c_height_spot_column)));

              
          let annulur_volume_required_hwdp = parseFloat(this.data["openholehwdpannularcapacity"]).toFixed(4) *
            (1 + parseFloat(this.washout_factor) / 100) *
            (this.c_height_spot_column &&
            Number((parseFloat(this.data["openholedc1length"]) + parseFloat(this.data["openholedc2length"])).toFixed(4)) <=
              parseFloat(this.c_height_spot_column) &&
            parseFloat(this.c_height_spot_column) <=
                Number((parseFloat(this.data["openholedc1length"]) + parseFloat(this.data["openholedc2length"])).toFixed(4)) +
                Number(parseFloat(this.data["openholehwdplength"]).toFixed(4))
              ? parseFloat(this.c_height_spot_column) -
              Number((parseFloat(this.data["openholedc1length"]) + 
                  parseFloat(this.data["openholedc2length"])).toFixed(4))
              : Number(parseFloat(this.data["openholehwdplength"]).toFixed(4)) -
              Number((parseFloat(this.data["openholedc1length"]) + 
                  parseFloat(this.data["openholedc2length"])).toFixed(4)))



          // let annulur_volume_required_hwdp =
          //   parseFloat(this.data["openholehwdpannularcapacity"]).toFixed(4) *
          //   (1 + parseFloat(this.washout_factor) / 100) *
          //   (this.c_height_spot_column &&
          //   parseFloat(this.data["openholedc1length"]).toFixed(4) <=
          //     parseFloat(this.c_height_spot_column) &&
          //   parseFloat(this.c_height_spot_column) <=
          //     parseFloat(this.data["openholedc1length"]).toFixed(4) +
          //       parseFloat(this.data["openholehwdplength"]).toFixed(4)
          //     ? parseFloat(this.c_height_spot_column) -
          //       parseFloat(this.data["openholedc1length"]).toFixed(4)
          //     : parseFloat(this.data["openholehwdplength"]).toFixed(4) -
          //       parseFloat(this.data["openholedc1length"]).toFixed(4));

          // let annulur_volume_required_dc2 =
          //   parseFloat(this.data["openholehwdpannularcapacity"]).toFixed(4) *
          //   (1 + parseFloat(this.washout_factor) / 100) *
          //   (this.c_height_spot_column &&
          //   parseFloat(this.data["openholedc1length"]).toFixed(4) <=
          //     parseFloat(this.c_height_spot_column) &&
          //   parseFloat(this.c_height_spot_column) <=
          //     parseFloat(this.data["openholedc1length"]).toFixed(4) +
          //       parseFloat(this.data["openholehwdplength"]).toFixed(4)
          //     ? parseFloat(this.c_height_spot_column) -
          //       parseFloat(this.data["openholedc1length"]).toFixed(4)
          //     : parseFloat(this.data["openholehwdplength"]).toFixed(4) -
          //       parseFloat(this.data["openholedc1length"]).toFixed(4));


          let annulur_volume_required_dc2 =
            parseFloat(this.data["openholedc2annularcapacity"]).toFixed(4) *
              (1 + parseFloat(this.washout_factor) / 100) *
              (this.c_height_spot_column &&
              Number(parseFloat(this.data["openholedc1length"]).toFixed(4)) <=
                parseFloat(this.c_height_spot_column) &&
              parseFloat(this.c_height_spot_column) <=
                Number(parseFloat(this.data["openholedc1length"]).toFixed(4)) +
                Number(parseFloat(this.data["openholedc2length"]).toFixed(4))
                ? parseFloat(this.c_height_spot_column) -
                  Number(parseFloat(this.data["openholedc1length"]).toFixed(4))
                : Number(parseFloat(this.data["openholedc2length"]).toFixed(4)) -
                 Number(parseFloat(this.data["openholedc1length"]).toFixed(4)));     

          let annulur_volume_required_dp1 =
            parseFloat(this.data["openholedp1annularcapacity"]).toFixed(4) *
            (1 + parseFloat(this.washout_factor) / 100) *
            (this.c_height_spot_column &&
            parseFloat(this.c_height_spot_column) >=
              parseFloat(
                Number(parseFloat(this.data["openholedc1length"]).toFixed(4)) +
                Number(parseFloat(this.data["openholedc2length"]).toFixed(4)) +
                Number(parseFloat(this.data["openholehwdplength"]).toFixed(4))
              ) &&
              parseFloat(this.c_height_spot_column) <=
                parseFloat(
                  Number(parseFloat(this.data["openholedc1length"]).toFixed(4)) +
                  Number(parseFloat(this.data["openholedc2length"]).toFixed(4)) +
                  Number(parseFloat(this.data["openholehwdplength"]).toFixed(4)) +
                  Number(parseFloat(this.data["openholedp1length"]).toFixed(4))
                )
              ? parseFloat(this.c_height_spot_column) -
                Number(parseFloat(this.data["openholedc1length"]).toFixed(4)) -
                Number(parseFloat(this.data["openholedc2length"]).toFixed(4)) -
                Number(parseFloat(this.data["openholehwdplength"]).toFixed(4))
              : Number(parseFloat(this.data["openholedp1length"]).toFixed(4)) -
                Number(parseFloat(this.data["openholedc1length"]).toFixed(4)) -
                Number(parseFloat(this.data["openholedc2length"]).toFixed(4)) -
                Number(parseFloat(this.data["openholehwdplength"]).toFixed(4)));
          
          let annulur_volume_required_dp2 =
            parseFloat(this.data["openholedp2annularcapacity"]).toFixed(4) *
            (1 + parseFloat(this.washout_factor) / 100) *
            (this.c_height_spot_column &&
            parseFloat(this.c_height_spot_column) >=
              parseFloat(
                Number(parseFloat(this.data["openholedc1length"]).toFixed(4)) +
                Number(parseFloat(this.data["openholedc2length"]).toFixed(4)) +
                  Number(parseFloat(this.data["openholehwdplength"]).toFixed(4)) +
                  Number(parseFloat(this.data["openholedp1length"]).toFixed(4)) 
              )
              &&
              parseFloat(this.c_height_spot_column) <= (
                Number(parseFloat(this.data["openholedc1length"]).toFixed(4)) +
                Number(parseFloat(this.data["openholedc2length"]).toFixed(4)) +
                  Number(parseFloat(this.data["openholehwdplength"]).toFixed(4)) +
                  Number(parseFloat(this.data["openholedp1length"]).toFixed(4)) + 
                  Number(parseFloat(this.data["openholedp2length"]).toFixed(4)) 
              )
              ? parseFloat(this.c_height_spot_column) -
                Number(parseFloat(this.data["openholedc1length"]).toFixed(4)) -
                Number(parseFloat(this.data["openholedc2length"]).toFixed(4)) -
                Number(parseFloat(this.data["openholehwdplength"]).toFixed(4)) - 
                Number(parseFloat(this.data["openholedp1length"]).toFixed(4)) 
              : Number(parseFloat(this.data["openholedp2length"]).toFixed(4)) -
                Number(parseFloat(this.data["openholedc1length"]).toFixed(4)) -
                Number(parseFloat(this.data["openholedc2length"]).toFixed(4)) -
                Number(parseFloat(this.data["openholehwdplength"]).toFixed(4)) - 
                Number(parseFloat(this.data["openholedp1length"]).toFixed(4)));      

          let annulur_volume_required =
            Number(annulur_volume_required_dp1) +
            Number(annulur_volume_required_dp2) +
            Number(annulur_volume_required_dc1) +
            Number(annulur_volume_required_dc2) +
            Number(annulur_volume_required_hwdp);
          // console.log(
          //   "annular volume values ~ ",
          //   parseFloat(this.data["openholedc1annularcapacity"]).toFixed(2),
          //   this.washout_factor,
          //   annulur_volume_required_dp1,
          //   annulur_volume_required_dp2,
          //   annulur_volume_required_dc1,
          //   annulur_volume_required_dc2,
          //   annulur_volume_required_hwdp
          // );

          // ~~~~~~ volumes ~~~~~~~  2.537135902386503 2.089202448027977 0.447933454358526 0
          let total_volume_spotting_fluid =
            annulur_volume_required +
            Number(parseFloat(this.c_drill_string_volume_left));
          let drill_string_capacity =   // this.data["drillstringvolume"]
            parseFloat(this.data["dc1stringvolume"]).toFixed(4) +
            parseFloat(this.data["dc2stringvolume"]).toFixed(4) +
            parseFloat(this.data["dp1stringvolume"]).toFixed(4) +
            parseFloat(this.data["dp2stringvolume"]).toFixed(4) +
            parseFloat(this.data["hwdpstringvolume"]).toFixed(4);

          let stroke_required_pump_pill =
            total_volume_spotting_fluid /
            parseFloat(this.data["pumpoutput"]).toFixed(4);

          let volume_required_pill_chase =
            Number(parseFloat(this.data["drillstringvolume"]).toFixed(4)) -
            Number(parseFloat(this.c_drill_string_volume_left));

          let stroke_required_chase_pill =
            volume_required_pill_chase /
              Number(parseFloat(this.data["pumpoutput"]).toFixed(4)) +
            Number(parseFloat(this.stroke_required_displace_surface));

          let spot_pill_strokes_required =
            Number(stroke_required_pump_pill) + Number(stroke_required_chase_pill);
          this.stroke_required_chase_pill = stroke_required_chase_pill;
          this.stroke_required_pump_pill = stroke_required_pump_pill;
          this.total_volume_spotting_fluid = total_volume_spotting_fluid;
          this.spot_pill_strokes_required = spot_pill_strokes_required;
          this.volume_required_pill_chase = volume_required_pill_chase;
        } else {
          this.volume_required_pill_chase = 0;
          this.stroke_required_pump_pill = 0;
          this.total_volume_spotting_fluid = 0;
          this.spot_pill_strokes_required = 0;
        }
      } catch (error) {
        console.error("error in slug calculations ", error);
      }
    },
    getRealtimeData(title) {
      let topic = `${this.wellId}-${this.wellboreId}-${this.logTypeId}-${this.$store.state.data.customer}`;
      let wells = this.$store.state.data.wells;
      let well = wells[this.wellId];
      if (well) {
        let wellbore = well.wellbores[this.wellboreId];
        let log = wellbore["logs"][this.logType];
        this.logId = log.id;
        let fields = this.fields_required;
        let topicExists = this.$store.state.live.isSubscribed[topic];
        if (this.wellId && this.wellboreId && this.logType) {
          this.$socket.emit("subscribe", {
            logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
            topic: this.topic,
            fields,
            id: this.widgetId,
            clientId: this.$clientId,
            logType: this.logType,
          });
          if (!topicExists) {
            this.$store.dispatch("live/setSubscriptionFlag", {
              topic: this.topic,
              flag: true,
            });
          }

          this.sockets.subscribe(this.$clientId, (sResult) => {
            // for (let i = 0; i < socketResult.length; i++) {
            //     let sResult = socketResult[i]
            let common = sResult["common"];
            let result = sResult[this.widgetId];
            // console.log(" checking hyd data : ", result, this.widgetId);
            if (result) {
              let length = result["dbtm"].length || 0;
              let temp_data = {};
              // console.log(
              //   "~~~~~~~~~~ this.data ~~~~~~~~~~~~~ ",
              //   this.data,
              //   length
              // );
              this.fields_required.map((field) => {
                if (
                  length &&
                  result[field] &&
                  result[field][length - 1] !== null &&
                  !isNaN(result[field][length - 1])
                ) {
                  temp_data[field] = parseFloat(result[field][length - 1]);
                } else {
                  temp_data[field] = 0;
                }
              });
              if (!this.isFirst) {
                this.data_initial = temp_data;
                this.isFirst = true;
              }
              this.data = temp_data;
              // d3.select(`#hydraulics-calculator${this.widgetId}`).selectAll('svg').remove();
              this.buildHydraulicsCalculator();
            }
            // }
          });
        }
        topicExists = this.$store.state.live.isSubscribed[topic];
        //
      }
    },
    takeOffSocket() {
      if (this.$socket) {
        // console.log("unsubscribe for hydraulics : ", this.widgetId);
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
        this.isFirst = false;
      }
    },
    showCasing() {
      //left casing context
      if(!this.leftCasingCanvas)
        this.leftCasingCanvas = this.$refs.left_casing;
      if(!this.rightCasingCanvas)
        this.rightCasingCanvas = this.$refs.right_casing;
      const lfCtx = this.leftCasingCanvas.getContext("2d");
      this.leftCasingCanvas.height = this.widgetHeight - 50;
      lfCtx.clearRect(
        0,
        0,
        this.leftCasingCanvas.width,
        this.leftCasingCanvas.height
      );
      // Define a new path:
      lfCtx.beginPath();
      lfCtx.fillStyle = "black";
      lfCtx.moveTo(120, 0);
      lfCtx.lineTo(10, 120);
      lfCtx.lineTo(120, 120);
      // lfCtx.lineTo(70,50);
      lfCtx.fill();
      // Draw it
      lfCtx.stroke();
      // right casing context
      const rTCtx = this.rightCasingCanvas.getContext("2d");
      this.rightCasingCanvas.height = this.widgetHeight - 50;
      rTCtx.clearRect(
        0,
        0,
        this.rightCasingCanvas.width,
        this.rightCasingCanvas.height
      );
      rTCtx.beginPath();
      rTCtx.fillStyle = "black";
      rTCtx.moveTo(0, 80);
      rTCtx.lineTo(40, 120);
      rTCtx.lineTo(0, 120);

      rTCtx.fill();
      // Draw it
      rTCtx.stroke();
    },
  },
  watch: {
    widgetHeight(newValue) {
      // d3.select(`#hydraulics-calculator${this.widgetId} svg`).remove();
      d3.select(`#hydraulics-calculator${this.widgetId}`)
        .selectAll("svg")
        .remove();
      this.buildHydraulicsCalculator();
    },
    widgetWidth(newValue) {
      // d3.select(`#hydraulics-calculator${this.widgetId} svg`).remove();
      d3.select(`#hydraulics-calculator${this.widgetId}`)
        .selectAll("svg")
        .remove();
      this.buildHydraulicsCalculator();
    },
    dry_pipe_length(newValue) {
      this.getSlugCalulationData();
    },
    slug_weight(newValue) {
      this.getSlugCalulationData();
    },
    slug_dry_pipe_length(newValue) {
      this.getSlugCalulationData();
    },
    slug_volume(newValue) {
      this.getSlugCalulationData();
    },
    annular_capacity(newValue) {
      this.getSlugCalulationData();
    },
    annular_slug_volume(newValue) {
      this.getSlugCalulationData();
    },
    washout_factor(newValue) {
      this.getSlugCalulationData();
    },
    drill_string_volume_left(newValue) {
      this.getSlugCalulationData();
    },
    stroke_required_displace_surface(newValue) {
      this.getSlugCalulationData();
    },
    height_spot_column(newValue) {
      this.getSlugCalulationData();
    },
    replayDetails(result) {
      let length = result["dbtm"].length || 0;
      let temp_data = {};
      this.fields_required.map((field) => {
        if (length && result[field] && result[field].length && result[field][length - 1] !== null && result[field][length - 1] !== '' && !isNaN(result[field][length - 1])) {
          temp_data[field] = parseFloat(result[field][length - 1]);
        } else {
          temp_data[field] = 0;
        }
      });
      if (!this.isFirst) {
        let initial_temp_data = {};
        this.fields_required.map((field) => {
          if (length && !isNaN(result[field][0])) {
            initial_temp_data[field] = parseFloat(result[field][0]);
          } else {
            initial_temp_data[field] = 0;
          }
        });

        this.data_initial = initial_temp_data;

        this.isFirst = true;
      }
      this.data = temp_data;
      d3.select(`#hydraulics-calculator${this.widgetId}`)
        .selectAll("svg")
        .remove();
      this.buildHydraulicsCalculator();
    },
  },
  setup() {
    const { isAuto, replayDetails } = useReplay();
    return {
      isAuto,
      replayDetails,
    };
  },
};
</script>
<style scoped>
[type="radio"] {
  /* background-color: #28282f !important; */
  margin-left: auto;
}

[type="radio"]:checked {
  border: 1px solid #5aba81 !important;
  background-color: #5aba81 !important;
}

.tooltip_hydraC {
  position: relative;
  display: block;
}

/* .tool_pop{
    background: #28282f;
} */
.tooltip_hydraC .tooltiptext {
  visibility: hidden;

  color: #fff;
  text-align: center;
  /* border-radius: 6px; */
  /* padding: 5px 10px; */

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  background: #28282f;
  right: 105%;
  width: 280px;
}

.tooltip_hydraC .tooltiptext::after {
  content: "";
  position: absolute;
  right: -6px;
  top: 5px;
  content: "\1F782";
  font-size: 14px;
  color: #005e62;
}

.tooltip_hydraC .tooltiptext {
  visibility: visible;
  /* background: #005e62; */
  z-index: 999999;
}

.tool_group_pop {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  z-index: 99999;
  background: unset;
}

.opt___s {
  /* width: 62%; */
  /* margin: 0 auto; */

  margin-top: 1%;
  justify-content: space-around;
  display: flex;
  padding: 5px;
  color: var(--textColor);
}

.text_color {
  color: var(--textColor);
}

.font_xs_tooltip {
  font-size: 10px;
}

.plygon_shadow {
  box-shadow: 0 0 5px black, 1px 1px 5px red, 2px 2px 5px green, 3px 3px 5px red;
}
</style>
